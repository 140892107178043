import React, { useState, useContext } from 'react'
import { VenueContext } from '../../../stores/VenueProvider'
import Box from '../../../components/Modal/Box'
import * as API from '../../../api/api.js'
import { t } from '../../../i18n'
import './CustomerBook.css'

const Field = ({ label, id, type, placeholder, required, autoComplete, value, onChange }) => (
    <div className="formRow">
        <label htmlFor={id} className="formRowLabel">{label}</label>
        <input
            className="formRowInput"
            id={id}
            type={type}
            placeholder={placeholder}
            required={required}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange} />
    </div>
)

const OptinToggle = ({ title, onChange }) => (
    <div className="guestbook-toggle">
        <label className="guestbook-switch">
            <input type="checkbox" onChange={onChange} />
            <span className="guestbook-slider round"></span>
        </label>
        <div className="guestbook-slider-title">{title}</div>
    </div>
)

const NumberField = ({ label, id, placeholder, required, autoComplete, value, onChange }) => (
    <div className="formRow">
        <label htmlFor={id} className="formRowLabel">{label}</label>
        <input
            className="formRowInput"
            id={id}
            type="number"
            placeholder={placeholder}
            required={required}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange}
            inputMode="numeric"
            pattern="[0-9]*" />
    </div>
)

export default (props) => {

    const { isVisible = false, onClose = () => { } } = props
    const venueStore = useContext(VenueContext)
    const expiringStorage = require('../../../helpers/expiringStorage')

    const [customerDetails, setCustomerDetails] = useState({
        lastname: '',
        firstname: '',
        phone: '',
        email: '',
        optin: false
    })
    const [detailsComplete, setDetailsComplete] = useState(false)
    const [processing, setProcessing] = useState(false)

    const optin = "Conserver mes données afin de recevoir des communications de l'établissement."

    const handleSubmit = event => {
        event.preventDefault()

        if (detailsComplete) {
            setProcessing(true)
        } else {
            return
        }


        API.addGuestEntry(venueStore.venue.id, customerDetails.lastname, customerDetails.firstname, customerDetails.email, customerDetails.phone, customerDetails.optin)
            .then(response => {
                setProcessing(false)
                // set expiry to 6 hours
                expiringStorage.set('welcome', true, 21600000)
                onClose()
            })
            .catch(error => {

            })
    }

    return (
        <Box isVisible={isVisible} width="90%" height="80%" effect="fadeInUpBox" onClickAway={onClose}>
            <div className="book">
                <div className="closeBook" onClick={onClose}>X</div>
                <div className="header">
                    <center><b>COVID-19 : vos coordonnées pour faciliter la recherche des « cas contacts »</b></center><br /><br />
                    Notre établissement est soumis au respect d’un protocole sanitaire spécifique, qui prévoit le recueil d’informations vous concernant, dans le cadre de la lutte contre l’épidémie de COVID-19.<br /><br />
                    Merci de compléter les informations suivantes.<br />
                </div>
                <form className="form" onSubmit={handleSubmit}>
                    <Field
                        label={t('Guestbook.lastname')}
                        id="lastname"
                        type="text"
                        placeholder={t('Guestbook.lastname')}
                        required
                        autoComplete="name"
                        value={customerDetails.lastname}
                        onChange={(e) => {
                            setCustomerDetails({ ...customerDetails, lastname: e.target.value })
                            setDetailsComplete(customerDetails.lastname.length && customerDetails.firstname.length && (customerDetails.email.length || customerDetails.phone.length))
                        }} />
                    <Field
                        label={t('Guestbook.firstname')}
                        id="firstname"
                        type="text"
                        placeholder={t('Guestbook.firstname')}
                        required
                        autoComplete="name"
                        value={customerDetails.firstname}
                        onChange={(e) => {
                            setCustomerDetails({ ...customerDetails, firstname: e.target.value })
                            setDetailsComplete(customerDetails.lastname.length && customerDetails.firstname.length && (customerDetails.email.length || customerDetails.phone.length))
                        }} />
                    <NumberField
                        label={t('Guestbook.phone')}
                        id="phone"
                        type="number"
                        placeholder={t('Guestbook.phone')}
                        required
                        autoComplete="email"
                        value={customerDetails.phone}
                        onChange={(e) => {
                            setCustomerDetails({ ...customerDetails, phone: e.target.value })
                            setDetailsComplete(customerDetails.lastname.length && customerDetails.firstname.length && (customerDetails.email.length || customerDetails.phone.length))
                        }} />
                    <Field
                        label={t('Guestbook.email')}
                        id="email"
                        type="email"
                        placeholder={t('Guestbook.email')}
                        required
                        autoComplete="email"
                        value={customerDetails.email}
                        onChange={(e) => {
                            setCustomerDetails({ ...customerDetails, email: e.target.value })
                            setDetailsComplete(customerDetails.lastname.length && customerDetails.firstname.length && (customerDetails.email.length || customerDetails.phone.length))
                        }} />
                    <OptinToggle title={optin} onChange={e => setCustomerDetails({ ...customerDetails, optin: e.target.value })} />
                    <input className="guestbook-submit" type="submit" disabled={processing} value={processing ? t('Payment.processing') : 'Envoyer'} />
                </form>
                <div className="footer">
                    Les informations recueillies sur ce formulaire sont enregistrées et utilisées uniquement par notre établissement.<br />
                Conformément aux obligations prévues dans le protocole sanitaire défini par arrêté préfectoral, vos données seront uniquement utilisées pour faciliter la recherche des « cas contacts » par les autorités sanitaires, et ne seront pas réutilisées à d’autres fins.<br />
                En cas de contamination de l’un des clients au moment de votre présence, ces informations pourront être communiquées aux autorités sanitaires compétentes (agents des CPAM, de l’assurance maladie et/ou de l’agence régionale de santé), afin de vous contacter et de vous indiquer le protocole sanitaire à suivre.<br />
                Vos données seront conservées pendant 14 jours à compter de leur collecte, et seront supprimées à l’issue de ce délai.<br />
                Vous pouvez accéder aux données vous concernant, les rectifier ou exercer votre droit à la limitation du traitement de vos données.<br />
                Pour exercer ces droits ou pour toute question sur le traitement de vos données, vous pouvez contacter {venueStore.venue.name} au {venueStore.venue.address}.<br />
                Si vous estimez, après nous avoir contactés, que vos droits Informatique et Libertés ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.<br />
                </div>
            </div>
        </Box>
    )
}