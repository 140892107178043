import {v4 as uuid} from 'uuid';

const config = {
  ANALYTICS_API_URL: window._env_.REACT_ANALYTICS_API_URL
}

const user = (() => {
  let token = localStorage.getItem('appero-user-token');
  if(token) {return token;}

  token = uuid();
  localStorage.setItem('appero-user-token', token);

  return token;
})();

const analytics = {
  track : (event, payload) => {
    try {
      fetch(`${config.ANALYTICS_API_URL}/events`, {
        method: "POST",
        headers : {
          "Content-Type": "application/json",
        },
        body : JSON.stringify({user, event, payload})
      });
    }
    catch(error){
      console.error(error);
    }
  }
}

export default analytics;

