import React from 'react'
import Modal from '../../../components/Modal/Modal'
import { t } from '../../../i18n'
import './CancelationModal.css'

export default (props) => {

    const { isVisible = false, onClose = () => {}, onCancel = () => {} } = props

    return (
        <Modal isVisible={isVisible} width="300" effect="fadeInUp">
            <div className="confirmationModal">
                <div className="confirmationMessage">{t('Confirmation.cancelMessage')}</div>
                <div className="confirmationButtons">
                    <div className="confirmationCancelButton" onClick={onCancel} style={{ color: 'red' }}>{t('Confirmation.cancelConfirmation')}</div>
                    <div className="confirmationCloseButton" onClick={onClose}>{t('Confirmation.cancelStay')}</div>
                </div>
            </div>
        </Modal>
    )
}