import React, { useContext } from "react";
import "./Sections.css";
import { Link } from "react-router-dom";
import { OrderContext } from '../../../stores/OrderProvider'


export default (props) => {
  const { sections } = props;
  const orderStore = useContext(OrderContext);

  return (
    <div className='sections-container'>
      {sections.map((section) => (
        <div className='section-card' key={section.id}>
          {section.external_url ? (
            <a href={section.external_url} target='_blank'>
              <div className='section-image'>
                <img src={section.image} alt='' />
              </div>
              <div className='section-info'>
                <div className={`section-name cst-section-name cst-global-section-name  cst-item-${section.id} `} data-cst='cst' style={{ position: "relative" }}>
                  {section.name}
                </div>
                {section.description ? (
                  <div
                    className={`section-description cst-section-description cst-global-section-description cst-item-${section.id} `}
                    data-cst='cst'
                    style={{ position: "relative" }}
                  >
                    {section.description}
                  </div>
                ) : null}
              </div>
            </a>
          ) : (
            orderStore.sections.find(s => s.id === section.id) && orderStore.sections.find(s => s.id === section.id).categories.length ?
              <Link
                to={{
                  pathname: "/menu",
                  state: {
                    menu: orderStore.sections.find(s => s.id === section.id),
                  },
                }}
              >
                <div className='section-image'>
                  <img src={section.image} alt='' />
                </div>
                <div className='section-info'>
                  <div className={`section-name cst-section-name cst-global-section-name cst-item-${section.id} `} data-cst='cst' style={{ position: "relative" }}>
                    {section.name}
                  </div>
                  {section.description ? (
                    <div
                      className={`section-description cst-section-description cst-global-section-description cst-item-${section.id} `}
                      data-cst='cst'
                      style={{ position: "relative" }}
                    >
                      {section.description}
                    </div>
                  ) : null}
                </div>
              </Link>
              :
              <>
                <div className='section-image'>
                  <img src={section.image} alt='' />
                  <div className="lds-ring">
                    <div></div><div></div><div></div><div></div>
                  </div>
                </div>
                <div className='section-info'>
                  <div className={`section-name cst-section-name cst-global-section-name cst-item-${section.id} `} data-cst='cst' style={{ position: "relative" }}>
                    {section.name}
                  </div>
                  {section.description ? (
                    <div
                      className={`section-description cst-section-description cst-global-section-description cst-item-${section.id} `}
                      data-cst='cst'
                      style={{ position: "relative" }}
                    >
                      {section.description}
                    </div>
                  ) : null}
                </div>
              </>
          )}
        </div>
      ))}
    </div>
  );
};
