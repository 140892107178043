import React from 'react'
import Modal from '../../../components/Modal/Modal'
import { t } from '../../../i18n'
import './ConfirmationModal.css'

export default (props) => {

    const { isVisible = false, onClose = () => {}, message = '' } = props

    return (
        <Modal isVisible={isVisible} width="300" effect="fadeInUp">
            <div className="confirmationModal">
                <div className="confirmationMessage">{message}</div>
                <div className="confirmationButton" onClick={onClose}>{t('Confirmation.ok')}</div>
            </div>
        </Modal>
    )
}
