import React from 'react'
import PropTypes from 'prop-types'
import './Loader.css'

const Loader = (props) => {
    return (props.visible ?
        <div role="presentation" className="container" style={{ margin: '0px auto' }}>
            <div className="loader-swing">
                <div className="loader-swing-l" />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div className="loader-swing-r" />
            </div>
            <div className="loader-shadow">
                <div className="loader-shadow-l" />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div className="loader-shadow-r" />
            </div>
        </div>
        : null
    )
}

Loader.propTypes = {
    style: PropTypes.objectOf(PropTypes.string),
    className: PropTypes.string,
    visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    timeout: PropTypes.number
}

Loader.defaultProps = {
    style: {},
    className: "loader",
    visible: true,
    timeout: 0
}

export default Loader