import fadeInDownBox from './boxeffect/fadeInDownBox'
import fadeInUpBox from './boxeffect/fadeInUpBox'
import fadeInLeftBox from './boxeffect/fadeInLeftBox'
import fadeInRightBox from './boxeffect/fadeInRightBox'

export default {
    fadeInDownBox : fadeInDownBox,
    fadeInUpBox : fadeInUpBox,
    fadeInLeftBox : fadeInLeftBox,
    fadeInRightBox : fadeInRightBox
}