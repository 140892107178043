export const APPERO_WEB_SELECTOR = "appero-web-selector";
export const APPERO_WEB_CHANGE_SUCCESSFUL = "appero-web-change-successful";
export const PREPARE_IFRAME = "prepare-iframe";
export const ENTER_SELECT_MODE = "enter-select-mode";
export const QUIT_SELECT_MODE = "quit-select-mode";
export const CHANGE_STYLE = "change-style";
export const CHANGE_SELECTOR = "change-selector";
export const CANCEL_SAVE_CURRENT_STYLE = "cancel-save-current-style";
export const RESTORE_STYLES = "restore-styles";
export const CUSTOM_STYLESHEET_ID = "custom-stylesheet";
export const SAVE_CURRENT_STYLES_REQUEST = "save-current-styles-request";
export const SHOW_HIDDEN_ELEMENTS = "show-hidden-elements";
export const HIDE_HIDDEN_ELEMENTS = "hide-hidden-elements";
export const RESET_ALL_STYLES = "reset-all-styles";
