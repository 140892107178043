import React from 'react'
import { t } from '../../../i18n'
import './ConfirmationFooter.css'

export default (props) => {

    const { onCancel = () => { } } = props

    return (
        <div className="confirmation-footer" onClick={onCancel}>{t('Confirmation.cancel')}</div>
    )
}