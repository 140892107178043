import React, { useContext, useState, useEffect } from 'react'
import { t } from '../../i18n'
import { OrderContext } from '../../stores/OrderProvider'
import { VenueContext } from '../../stores/VenueProvider'
import TopBar from '../../components/TopBar'
import Inset from '../../components/Inset'
import StyledText from '../../components/StyledText'
import OrderList from '../../pages/Order/components/OrderList'
import Loader from './components/Loader'
import Passphrase from './components/Passphrase'
import './Confirmation.css'
import { ws } from '../../io/io'
import ConfirmationModal from './components/ConfirmationModal'
import CancelationModal from './components/CancelationModal'
import VenueCancelationModal from './components/VenueCancelationModal'
import OnHoldModal from './components/OnHoldModal'
import ConfirmationFooter from './components/ConfirmationFooter'
import * as API from '../../api/api.js'
import { Redirect } from 'react-router-dom'

const styles = {
    headline: {
        textAlign: 'center',
        fontWeight: 'bold',
        marginTop: '20px',
        marginBottom: '10px'
    },
    comment: {
        textAlign: 'center',
        marginBottom: '30px',
        marginLeft: '20px',
        marginRight: '20px'
    },
    password: {
        textAlign: 'center',
        fontWeight: 'bold',
        margin: '30px auto 0px auto',
        whiteSpace: 'pre-line'
    }
}

export default () => {
    const orderStore = useContext(OrderContext)
    const venueStore = useContext(VenueContext)
    const tableService = orderStore.order.table !== null
    //Venues with a partner integration have a slightly different journey (no user cancelation possible, for example)
    const integration = venueStore.venue.integration.length > 0

    const [showModal, setShowModal] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [showVenueCancelModal, setShowVenueCancelModal] = useState(false)
    const [showOnHoldModal, setShowOnHoldModal] = useState(false)
    const [orderPaid, setOrderPaid] = useState(false)
    const [orderCanceled, setOrderCanceled] = useState(false)
    const [orderOnHold, setOrderOnHold] = useState(false)
    const [cancelable, setCancelable] = useState(!integration)
    const [customerMessage, setCustomerMessage] = useState(integration ? t('Confirmation.inProgress') : t('Confirmation.wait'))
    const [queue, setQueue] = useState(venueStore.queue)

    const onCancel = () => {
        API.deleteOrder(orderStore.order.id).then(
            () => {
                orderStore.cleanUp()
                setShowCancelModal(false)
                setOrderCanceled(true)
            }
        )
    }

    const updateOrder = data => {
        let serverId = data.id
        let localId = orderStore.order.id
        let status = data.status
        if (serverId === localId) {
            // Multiple renders could cause several attempts to charge the customer.
            // We check the order's status to prevent this.
            if (orderStore.order.status === status) return;

            switch (status) {
                case 'IN_PROGRESS':
                    orderStore.setStatus('in_progress')
                    setModalMessage(t('Confirmation.inProgress'))
                    setCustomerMessage(t('Confirmation.inProgress'))
                    setCancelable(false)
                    setShowModal(true)
                    break
                case 'READY':
                    orderStore.setStatus('ready')
                    if (tableService) {
                        setCustomerMessage(t('Confirmation.ready_table'))
                        setModalMessage(t('Confirmation.ready_table'))
                    } else {
                        setCustomerMessage(t('Confirmation.ready'))
                        setModalMessage(t('Confirmation.ready'))
                    }
                    setShowModal(true)
                    break
                case 'DELIVERED':
                    orderStore.cleanUp()
                    setOrderPaid(true)
                    break
                case 'ON_HOLD':
                    orderStore.setStatus('on_hold')
                    const products = {}
                    data.items.forEach(line => {
                        products[line.uuid] = { qty: line.qty, bundle_id: line.bundle_id, net_price: line.net_price }
                    })
                    const total = data.items.map(line => ({ net_price: line.net_price })).reduce((net_price, sum = 0) => sum + net_price).net_price
                    orderStore.replaceOrder(products, total)
                    API.cancelPaymentIntent(venueStore.venue.id, orderStore.order.intent).then(({ intent, status }) => { orderStore.setIntent(null) })
                    setShowOnHoldModal(true)
                    break
                default:
                    break
            }
        } else {
            switch (status) {
                case 'DELIVERED':
                    setQueue(queue > 1 ? queue - 1 : queue)
                    break
                default:
                    break
            }
        }
    }

    const cancelOrder = data => {
        let serverId = data.order
        let localId = orderStore.order.id
        if (serverId === localId) {
            setShowVenueCancelModal(true)
        }
    }

    const Header = () => {
        return (
            <div>
                <TopBar title={t('Order.title')} />
                <Inset value="60px" />
            </div>
        )
    }

    const Footer = () => {
        return (
            <div>
                <Inset value="60px" />
                <ConfirmationFooter onCancel={() => setShowCancelModal(true)} />
            </div>
        )
    }

    const origin = orderStore.order.table ? '/table/' + orderStore.order.table : '/'

    useEffect(() => {
        ws.on('order:update', updateOrder)
        ws.on('order:cancel', cancelOrder)
    }, [])

    if (tableService) {
        return orderPaid || orderCanceled ?
            <Redirect to={origin} />
            :
            orderOnHold ?
                <Redirect to="/order" />
                :
                (
                    <>
                        <Header />
                        <StyledText style={styles.headline} text={t('Confirmation.thanks')} />
                        <StyledText style={styles.comment} text={customerMessage} />
                        {cancelable && queue > 0 ? <StyledText style={styles.comment} text={t('Confirmation.queue', { count: queue })} /> : null}
                        <Loader />
                        <OrderList order={orderStore.order} products={orderStore.products} editable={false} />
                        {cancelable ? <Footer /> : null}
                        <ConfirmationModal message={modalMessage} isVisible={showModal} onClose={() => {
                            if (integration && orderStore.order.status === 'ready') {
                                API.deliverOrder(orderStore.order.id).then(() => setShowModal(false))
                            } else {
                                setShowModal(false)
                            }
                        }} />
                        <CancelationModal isVisible={showCancelModal} onClose={() => setShowCancelModal(false)} onCancel={onCancel} />
                        <VenueCancelationModal isVisible={showVenueCancelModal} onClose={() => { setShowVenueCancelModal(false); orderStore.cleanUp(); setOrderCanceled(true) }} />
                        <OnHoldModal isVisible={showOnHoldModal} onClose={() => { setShowOnHoldModal(false); setOrderOnHold(true) }} />
                    </>
                )
    } else {
        return orderPaid || orderCanceled ?
            <Redirect to={origin} />
            :
            orderOnHold ?
                <Redirect to="/order" />
                :
                (
                    <>
                        <Header />
                        <StyledText style={styles.headline} text={t('Confirmation.thanks')} />
                        <StyledText style={styles.comment} text={customerMessage} />
                        {cancelable && queue > 0 ? <StyledText style={styles.comment} text={t('Confirmation.queue', { count: queue })} /> : null}
                        <Loader />
                        <StyledText style={styles.password} text={t('Confirmation.password')} />
                        <Passphrase passphrase={orderStore.order.passphrase} />
                        <OrderList order={orderStore.order} products={orderStore.products} editable={false} />
                        {cancelable ? <Footer /> : null}
                        <ConfirmationModal message={modalMessage} isVisible={showModal} onClose={() => {
                            if (integration && orderStore.order.status === 'ready') {
                                API.deliverOrder(orderStore.order.id).then(() => setShowModal(false))
                            } else {
                                setShowModal(false)
                            }
                        }} />
                        <CancelationModal isVisible={showCancelModal} onClose={() => setShowCancelModal(false)} onCancel={onCancel} />
                        <VenueCancelationModal isVisible={showVenueCancelModal} onClose={() => { setShowVenueCancelModal(false); orderStore.cleanUp(); setOrderCanceled(true) }} />
                        <OnHoldModal isVisible={showOnHoldModal} onClose={() => { setShowOnHoldModal(false); setOrderOnHold(true) }} />
                    </>
                )
    }
}