/*
  The solution below is the preferred one as it handles different currencies
  and different locales. At the time of writing there is a bug in Android that
  prevent toLocaleString to work properly. Replace toFixed with toLocaleString
  if i18n is required and the bug is fixed in Android.

  See https://github.com/facebook/react-native/issues/15717

  {total.toLocaleString(undefined, {style: "currency", currency : 'EUR'})}
 */

import React from 'react'
import Currency from 'react-currency-formatter-v2'

const formatCurrency = (number, currency) => <Currency quantity={number} currency={currency} />

export default formatCurrency
