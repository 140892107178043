import React, { useContext } from 'react'
import { VenueContext } from '../../../stores/VenueProvider'
import Modal from '../../../components/Modal/Modal'
import { t } from '../../../i18n'
import './ConfirmationModal.css'

export default (props) => {

    const { isVisible = false, onClose = () => {} } = props
    const venueStore = useContext(VenueContext)

    return (
        <Modal isVisible={isVisible} width="300" effect="fadeInUp">
            <div className="confirmationModal">
                <div className="confirmationMessage">{t('Confirmation.cancelledByVenue', { name: venueStore.venue.name })}</div>
                <div className="confirmationButton" onClick={onClose}>{t('Confirmation.ok')}</div>
            </div>
        </Modal>
    )
}
