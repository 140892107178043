import React, { useState } from 'react'
import './RadioSelect.css'

export default props => {
    const [selected, setSelected] = useState(null)

    return (
        <div className="radioSelect" onChange={props.onChange}>
            <div className="radioSelect-name">{props.name}</div>
            {props.options.map((option, index) =>
                <div key ={`option-${index}`} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div className={props.selectOptionClassname || "radioSelect-option"}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <label className={props.selectOptionLabelClassname || option.description ? "radioSelect-option-name-bold" : "radioSelect-option-name"}>{option.name}</label>
                            {option.description ?
                                <div className={props.selectOptionLabelClassname || "radioSelect-option-description"}>{option.description}</div> : null}
                        </div>
                        {
                            props.isMulti ?
                                <input type="checkbox"
                                    className={props.selectInputClassname || "radioSelect-input-multi"}
                                    name={option.name}
                                    value={option.id}
                                    onChange={() => setSelected(option.id)} />
                                :
                                <input type="radio"
                                    className={props.selectInputClassname || "radioSelect-input"}
                                    name={option.name}
                                    value={option.id}
                                    onChange={() => setSelected(option.id)} checked={selected === option.id} />
                        }
                    </div>
                    <div className="radioSelect-children">{option.children}</div>
                </div>
            )}
        </div>
    )
}