import React from "react";
import { t } from "../i18n";
import instagram from "../images/instagram-icon.png";
import facebook from "../images/facebook-icon.png";
import tripadvisor from "../images/tripadvisor-icon.png";
import gmaps from "../images/gmaps-icon.png";
import "./Footer.css";

export default (props) => {
  const venue = props.venue;

  return (
    <div className='appero-footer cst-appero-footer cst-global-appero-footer'>
      <div className='social-networks'>
        {venue.instagram ? (
          <div className='social-networks-instagram'>
            <a href={venue.instagram} target='_blank' rel='noopener'>
              <img src={instagram} className='icon' alt='insta' />
            </a>
          </div>
        ) : null}
        {venue.facebook ? (
          <div className='social-networks-facebook'>
            <a href={venue.facebook} target='_blank' rel='noopener'>
              <img src={facebook} className='icon' alt='fb' />
            </a>
          </div>
        ) : null}
        {venue.tripadvisor ? (
          <div className='social-networks-tripadvisor'>
            <a href={venue.tripadvisor} target='_blank' rel='noopener'>
              <img src={tripadvisor} className='icon' alt='fb' />
            </a>
          </div>
        ) : null}
        {venue.gmaps ? (
          <div className='social-networks-gmaps'>
            <a href={venue.gmaps} target='_blank' rel='noopener'>
              <img src={gmaps} className='icon' alt='fb' />
            </a>
          </div>
        ) : null}
      </div>
      {venue.website ? (
        <div className='social-networks-website'>
          <a href={venue.website} target='_blank' rel='noopener'>
            {t("Venue.website")}
          </a>
        </div>
      ) : null}
      {venue.legal ? (
        <div className='appero-footer-legal cst-appero-footer-legal' data-cst='cst' style={{ position: "relative" }}>
          {venue.legal}
        </div>
      ) : null}
      <a className='appero-footer-powered' href='https://www.appero.co' target='_blank' rel='noopener'>
        POWERED BY APPERO.CO
      </a>
      <div style={{ height: 80 }} />
    </div>
  );
};
