import React, { useContext } from 'react'
import { VenueContext } from '../../../stores/VenueProvider'
import formatCurrency from '../../../helpers/formatCurrency'
import list from '../../../images/list.png'
import { t } from '../../../i18n'
import './Cart.css'

export default (props) => {
    const { count, total } = props
    const venueStore = useContext(VenueContext)
    const listEnabled = venueStore.venue.wishlist
    const payment = venueStore.currentMenu.payment

    return (
        count > 0 && <div className="bottom-container">
            <div className="cart-container">
                {/* <div className="cart-order">{listEnabled && !payment ? t('Cart.myList', { count: count }) : t('Cart.myOrder', { count: count })}</div> */}
                <div className="cart-count">{count}</div>
                <img src={list} alt="" height={40} width={40} />
                {/* <div className="cart-total">{formatCurrency(total, venueStore.venue.currency)}</div> */}
            </div>
        </div>
    )
}


