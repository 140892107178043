import React, { useContext } from 'react'
import { OrderContext } from '../../../stores/OrderProvider'
import './NoteField.css'
import { t } from '../../../i18n'

export default () => {

    const orderStore = useContext(OrderContext)

    return (
        <div className="note-input" >
            <div className="note-title">{t('Order.note')}</div>
            <textarea value={orderStore.order.note || ''} placeholder={t('Order.notePlaceholder')} onChange={(event) => orderStore.setNote(event.target.value)} />
        </div>
    )
}