import React, { useContext } from "react";
import "./Info.css";
import { t } from "../../../i18n";
import { VenueContext } from "../../../stores/VenueProvider";

export default (props) => {
  const venueStore = useContext(VenueContext);
  const banners = venueStore.banners || [];
  return (
    <div className='info-container'>
      {/* {venue.happy_hour !== null && <div className="info-happy-hours">{t('Venue.happyHours', { start: venue.happy_hour.start, end: venue.happy_hour.end })}</div>} */}
      {/* {banners.length
        ? banners.map((banner) => (
            <div className={`info-happy-hours banner cst-banner cst-item-${banner.id}`} style={{ position: "relative" }} data-cst='cst'>
              {banner.text}
            </div>
          ))
        : null} */}
    </div>
  );
};
