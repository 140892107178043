import React, { useContext, useEffect, useState } from 'react'
import { OrderContext } from '../../stores/OrderProvider'
import ProductContent from './components/ProductContent'
import BundleContent from './components/BundleContent'
import SetContent from './components/SetContent'
import './Product.css'

export default props => {
    const orderStore = useContext(OrderContext)
    const id = window.location.href.substr(window.location.href.lastIndexOf('/') + 1)
    const product = orderStore.products[id]
    const [cover, setCover] = useState(false)

    const Content = () => {
        switch (product.type_id) {
            case 1: return (<ProductContent product={product} />)
            case 2: return (<BundleContent product={product} />)
            case 3: return (<SetContent product={product} />)
            default: return null
        }
    }

    const getMeta = (url, callback) => {
        var img = new Image()
        img.src = url
        img.onload = () => {
            callback(img, img.width / img.height)
        }
    }

    var localStorage = require('local-storage')

    useEffect(() => {
        if (product.image) { getMeta(product.image, (image, ratio) => setCover(ratio > 1)) }
        if (localStorage.get('language') === 'AR' || localStorage.get('language') === 'IL') {
            document.documentElement.dir = 'rtl'
        } else {
            document.documentElement.dir = 'ltr'
        }
    }, [])

    return (
        <div className="product-fp cst-product-fp cst-global-product-fp">
            {product.image ?
                <div className={!!cover ? "product-fp-image-container-landscape" : "product-fp-image-container-portrait"}>
                    <div className={"product-fp-image"}><img src={product.image} className={` product-fp-image-img cst-product-image-fp cst-item-${product.id}`} data-cst="cst" alt="" /></div>
                </div>
                :
                <div style={{ height: '60px', backgroundColor: 'white' }} />
            }
            <Content />
        </div>
    )
}