import React, { useState, useEffect, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom'
import Cart from '../../pages/Menu/components/Cart'
import Info from '../../pages/Venue/components/Info'
import Sections from '../../pages/Venue/components/Sections'
import CustomerBook from '../../pages/Venue/components/CustomerBook'
import StyledText from '../../components/StyledText'
import Footer from '../../components/Footer'
import * as API from '../../api/api.js'
import { VenueContext } from '../../stores/VenueProvider'
import { OrderContext } from '../../stores/OrderProvider'
import Dropdown from 'react-dropdown'
import './Venue.css'
import { t, changeLanguage } from '../../i18n'
import { ws } from '../../io/io'
// import { fetchTags } from "../../components/tags/fetchTags"
// import { fetchBanners } from "../../components/banners/fetchBanners"
import analytics from '../../api/analytics';

const styles = {
    error: {
        // fontFamily: "Muli",
        fontSize: "20px",
        fontStyle: "bold",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 20px",
    },
    header: {
        position: "absolute",
        marginTop: "190px",
        // fontFamily: "Muli",
        width: "100%",
        padding: "10px 20px 0px 20px",
        borderRadius: "40px 40px 0px 0px",
        fontWeight: "bold",
    },
    image: {
        objectFit: "cover",
        position: "fixed",
    },
    title: {
        // display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "0px 20px 10px 20px",
    },
};
export default (props) => {
    const venueStore = useContext(VenueContext)
    const orderStore = useContext(OrderContext)
    const [menus, setMenus] = useState(orderStore.sections.filter(s => !s.unavailable))
    const [error, setError] = useState(null)
    const [boxVisible, setBoxVisible] = useState(false)
    const [selectedLanguage, setSelectedLanguage] = useState({})
    const [flags, setFlags] = useState([])
    let location = useLocation()

    const expiringStorage = require("../../helpers/expiringStorage");
    var localStorage = require("local-storage");

    const updateSections = data => {
        let sections = JSON.parse(localStorage.get('sections'))
        let section = sections.find(s => s.id === data.section.id)
        if (section) {
            for (let key in data.section) {
                if (key === "languages") {
                    let language = data.section[key].find(l => l.locale.slice(-2).toUpperCase() === localStorage.get("language"))
                    section.name = language ? language.name : data.section.name
                    section.description = language ? language.description : data.section.description
                } else {
                    section[key] = data.section[key]
                }
            }
            orderStore.setSections(sections)
            setMenus(sections.filter(s => !s.unavailable).sort((a,b) => a.position - b.position))
        }
    }

    useEffect(() => {
        localStorage.remove("currentMenu")
        const venueId = window.location.hash.slice(1).split("/")[0];
        const { history } = props;
        history.listen((newLocation, action) => {
            if (action === "POP" && newLocation.pathname === "/payment") {
                history.go(1);
            }
        });

        if (localStorage.get("language") === "undefined") {
            localStorage.remove("language")
        }

        orderStore.setSummary({})

        const storedVenue = venueStore.venue
        if (storedVenue && storedVenue.id && storedVenue.id !== venueId) {
            venueStore.resetVenue();
            orderStore.cleanUp();
        }

        if (venueId) {
            let selected = localStorage.get("language")
            if (selected === 'GB') { selected = 'EN' }
            getMenu(venueId, selected);
            // getTags();
            // getBanners();
        } else {
            window.location.replace(`${process.env.PUBLIC_URL}/#${window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)}`);
        }

        return () => {
            ws.off('section:update')
        }
    }, [])

    // const getTags = async () => {
    //     const tags = await fetchTags(venueStore.venue.id, localStorage.get("language"));
    //     venueStore.setTags(tags);
    // };

    // const getBanners = async () => {
    //     const banners = await fetchBanners(venueStore.venue.id, localStorage.get("language"));
    //     venueStore.setBanners(banners);
    // };

    const updateLanguage = (option) => {
        setSelectedLanguage(option);
        let selected = option.value
        if (selected === "AR" || selected === "IL") {
            document.documentElement.dir = "rtl";
        } else {
            document.documentElement.dir = "ltr";
        }
        localStorage.set("language", selected);
        changeLanguage(selected);
        // English needs special handling
        if (selected === 'GB') { selected = 'EN'}
        getMenu(venueStore.venue.id, selected.toLowerCase() + "-" + selected);
    };

    const getMenu = (venueId, language) => {
        API.getVenue(venueId, language).then(({ sections, venue }) => {
            venueStore.setVenue(venue)
            if (venue.welcome_box && !expiringStorage.get("welcome")) {
                setBoxVisible(venue.welcome_box);
            }
            const name = venue.name;
            analytics.track("WEB_PAGE_ACCESS", { name })
            window.amplitude.getInstance().logEvent("WEB_PAGE_ACCESS", { name });
            analytics.track("USER_LANGUAGE", { language: navigator.language, venue_uuid: venueStore.venue.id, venue_name: name })
            window.amplitude.getInstance().logEvent("USER_LANGUAGE", { language: navigator.language, venue_uuid: venueStore.venue.id, venue_name: name })
            if (localStorage.get("language") === null) {
                localStorage.set("language", venue.supportedLanguages[0]);
                if (venue.supportedLanguages[0] === "AR" || venue.supportedLanguages[0] === "IL") {
                    document.documentElement.dir = "rtl";
                } else {
                    document.documentElement.dir = "ltr";
                }
            }
            setSelectedLanguage({
                label: <img className='dropdown-flag' src={require(`../../images/flags/${localStorage.get("language")}.svg`)} height={12} />,
                value: localStorage.get("language"),
            });
            setFlags(
                (venue.supportedLanguages || []).map((language) => ({
                    label: <img className='dropdown-flag' src={require(`../../images/flags/${language}.svg`)} height={12} />,
                    value: language,
                }))
            );
            if (sections) {
                setMenus(sections.filter(s => !s.unavailable))
                if (location.pathname.includes('/table')) {
                    const params = location.pathname.split('/');
                    const table = params[2];
                    orderStore.enableTableInput(false);
                    orderStore.setTable(table);
                } else {
                    orderStore.setTable(null);
                }
                ws.on('section:update', updateSections);
            } else {
                // The venue does not have an active subscription, display an error message
                setError(t("Venue.no_subscription"));
            }
        })
        API.getMenu(venueId, language).then(({ sections, instructions, queue, costs }) => {
            if (instructions) {
                orderStore.setInstructions(instructions);
            }
            venueStore.setQueue(queue);
            if (sections) {
                orderStore.setSections(sections);
                if (location.pathname.includes('/table')) {
                    orderStore.setCosts(costs.filter(c => c.table === true));
                } else {
                    orderStore.setCosts(costs.filter(c => c.bar === true));
                }
                ws.on('section:update', updateSections);
            }
        })
    }

    const listEnabled = venueStore.venue.wishlist
    const payment = menus.some(s => !!s.payment === true)
    const tableService = menus.some(s => !!s.table_service === true)

    return (
        <div className='venue-page'>
            <img src={venueStore.venue.image} height={"250px"} width={"100%"} style={styles.image} />
            <div className='venue-container' style={{ width: "100%", display: "flex", flexDirection: "column", backgroundColor: "white" }}>
                <div id='venue-header' className='venue-header cst-venue-header cst-global-venue-header' style={styles.header}>
                    {(venueStore.venue.supportedLanguages || []).length > 1 ? (
                        <div id='venue-header-title' className='venue-header-title' style={{ ...styles.title, position: "relative" }}>
                            <div style={{ width: "50px" }} />
                            <div
                                className={`${venueStore.venue.name && venueStore.venue.name.length >= 20 ? "venue-header-title" : "venue-header-title-big"
                                    }  cst-venue-name cst-global-venue-name  `}
                                data-cst='cst'
                                style={{ textAlign: "center" }}
                            >
                                {venueStore.venue.display_name ? venueStore.venue.name : ""}
                            </div>
                            <Dropdown
                                options={flags}
                                onChange={(option) => updateLanguage(option)}
                                value={selectedLanguage}
                                placeholder={<img className='dropdown-flag' src={require("../../images/flags/FR.svg")} height={12} />}
                            />
                        </div>
                    ) : (
                        venueStore.venue.display_name && (
                            <div
                                className={`${venueStore.venue.name && venueStore.venue.name.length >= 20 ? "venue-header-title" : "venue-header-title-big"
                                    }  cst-venue-name cst-global-venue-name `}
                                data-cst='cst'
                                style={{ ...styles.title, justifyContent: "center", position: "relative" }}
                            >
                                {venueStore.venue.name}
                            </div>
                        )
                    )}
                    <Info id='info-container' venue={venueStore.venue} />
                    <Sections sections={menus} />
                    <Footer venue={venueStore.venue} />
                    {error && <StyledText style={styles.error} text={error} />}
                    <CustomerBook
                        isVisible={boxVisible}
                        onClose={() => {
                            setBoxVisible(false);
                            // prevent redisplaying the box if customer closes it
                            expiringStorage.set("welcome", true, 21600000);
                        }}
                    />
                </div>
                {
                    (!!payment || !!listEnabled) &&
                    <Link to={{
                        pathname: "/order",
                        state: {
                            table_service: tableService || orderStore.order.table != null,
                        }
                    }}>
                        <Cart count={orderStore.order.count} total={orderStore.order.total} />
                    </Link>
                }
            </div>
        </div>
    )
}