import React, { useContext, useEffect, useState } from 'react'
import { t } from '../../i18n'
import { OrderContext } from '../../stores/OrderProvider'
import { VenueContext } from '../../stores/VenueProvider'
import OrderList from './components/OrderList'
import TopBar from '../../components/TopBar'
import TableInput from './components/TableInput'
import NoteField from './components/NoteField'
import CostsModal from './components/CostsModal'
import { Link, useHistory } from 'react-router-dom'
import './Order.css'

export default props => {
    const orderStore = useContext(OrderContext)
    const venueStore = useContext(VenueContext)
    const history = useHistory()

    const tableService = (props.location.state ? props.location.state : venueStore.currentMenu).table_service
    const [cost, setCost] = useState(null)
    const [showCostsModal, setShowCostsModal] = useState(false)
    const [hasSeenCostsPopup, setHasSeenCostsPopup] = useState(false)

    const listEnabled = venueStore.venue.wishlist
    const payment = venueStore.currentMenu.payment

    const OrderFooter = props => {
        const manualCosts = orderStore.order.costs.filter(c => c.manual === 1)
        const manualCost = manualCosts.length ? manualCosts[0] : null

        const showPopup = cost => {
            setCost(cost)
            setShowCostsModal(true)
            setHasSeenCostsPopup(true)
        }

        return (
            listEnabled && !payment ?
                <div className="order-footer" onClick={() => history.goBack()}>{t("Order.back")}</div>
                :
                manualCost && !hasSeenCostsPopup ?
                    <div className="order-footer" onClick={() => { showPopup(manualCost) }}>{t('Order.checkout')}</div>
                    :
                    <Link to="/payment">
                        <div className="order-footer">{t('Order.checkout')}</div>
                    </Link>
        )
    }

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'auto' })
        }, 250)
    })

    if (tableService || orderStore.order.table) {
        return (
            <div className="order-page">
                <TopBar title={t('Order.title')} />
                <TableInput />
                <OrderList order={orderStore.order} products={orderStore.products} editable={true} />
                <NoteField />
                {
                    orderStore.order.table ? <OrderFooter /> : <div className="order-footer-disabled">{t('Order.checkout')}</div>
                }
                <CostsModal product={cost} isVisible={showCostsModal} onClose={() => { setShowCostsModal(false) }} />
            </div>
        )
    } else {
        return (
            <div className="order-page">
                {listEnabled && !payment ? <TopBar title={t('Order.myList')} /> : <TopBar title={t('Order.title')} />}
                <OrderList order={orderStore.order} products={orderStore.products} editable={true} />
                {
                    listEnabled && !payment ?
                        <>
                            <OrderFooter />
                        </>
                        :
                        <>
                            <NoteField />
                            <OrderFooter />
                            <CostsModal product={cost} isVisible={showCostsModal} onClose={() => { setShowCostsModal(false) }} />
                        </>
                }
            </div>
        )
    }
}