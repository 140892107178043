import { t } from '../i18n'

const config = {
  API_URL: window._env_.REACT_APP_API_URL
}

const TIMEOUT = 60000

var __token = null

const __prepareOptions = (options = {}) => {
  if (__token) {
    const headers = options.headers || {}

    return {
      ...options,
      headers: {
        ...headers,
        'Authorization': `Bearer ${__token}`
      }
    }
  }

  return options
}

const __composeURL = (path, params = {}) => {
  path = path instanceof Array ? path.join('/') : path

  let url = config.API_URL + '/' + path
  let searchParams = Object.entries(params).map(([param, value]) => {
    const encodedValue = Array.isArray(value) ? value.map(item => encodeURIComponent(item)).join(',') : encodeURIComponent(value)
    return `${encodeURIComponent(param)}=${encodedValue}`
  }).join('&')

  if (searchParams) url += '?' + searchParams

  return url
}


const __fetch = (path, params, options) =>
  __timeout(fetch(__composeURL(path, params), __prepareOptions(options))
    .then(
      response => response.text().then(
        text => {
          if (response.ok) {
            return text && JSON.parse(text)
          } else {
            const json = text && JSON.parse(text)

            return Promise.reject({
              status: response.status,
              message: typeof json === 'string' ? json : (json || { message: '' }).message,
              error: json
            })
          }
        }
      ),
      error => Promise.reject({
        status: 0,
        message: error.message,
        error: error
      })
    ), TIMEOUT)

const __timeout = (promise, ms) => {
  return new Promise((resolve, reject) => {

    const timeout = setTimeout(() => {
      const error = new Error('timeout')
      error.status = 0
      reject(error)
    }, ms)

    promise.then(resolve, reject).finally(() => clearTimeout(timeout));
  })
}



/*
Retrieve venue information
 */
export const getVenue = (venueId, language) => {
  const now = new Date()
  const ts = now.getDay() + '_' + now.getHours() + '_' + now.getMinutes()
  return Promise.all([
    __fetch(['menu', venueId, language ? language : navigator.language, 'section'], { ts: ts }),
    __fetch(['venue', venueId]),
  ])
    .then(
      ([{ sections }, venue]) => ({
        sections,
        venue,
      })
    )
}

export const getMenu = (venueId, language) => {
  const now = new Date()
  const ts = now.getDay() + '_' + now.getHours() + '_' + now.getMinutes()
  return Promise.all([
    __fetch(['menu', venueId, language ? language : navigator.language, 'product'], { ts: ts }),
    __fetch(['order', venueId, 'queue']),
    __fetch(['menu', venueId, language ? language : navigator.language, 'cost']),
  ])
    .then(
      ([{ sections, instructions }, { queue }, costs]) => ({
        sections,
        instructions,
        queue,
        costs
      })
    )
}

export const addGuestEntry = (venueId, lastname, firstname, email, phone, optin) =>
  __fetch(['user', venueId, 'guest'], {}, {
    method: 'POST',
    body: JSON.stringify({ lastname, firstname, email, phone, optin }),
    headers: { 'Content-Type': 'application/json' }
  })

/*
Create customer if not existing or get existing one
*/
export const createCustomer = (name, email, phone, newsletter) => {
  const identity = name.split(" ")
  const firstname = identity[0]
  const lastname = identity.length > 1 ? identity[1] : null
  return __fetch(['user', 'customer'], {}, {
    method: 'POST',
    body: JSON.stringify({ firstname, lastname, email, phone, newsletter }),
    headers: { 'Content-Type': 'application/json' }
  })
    .then(
      ({ id, firstname, lastname, email, phone }) => {
        var localStorage = require('local-storage')
        localStorage.set('userId', id)
        localStorage.set('name', firstname)
        localStorage.set('lastname', lastname)
        if (email) localStorage.set('email', email)

        return { id, firstname, lastname, email }
      }
    )
    .catch(error => {
      if (error.status === 500) {
        return { message: t('Payment.error_phone') }
      }
    })
}


export const createOrder = (userId, intentId, venue, playerId, tableNumber) =>
  __fetch(['order'], {}, {
    method: 'POST',
    body: JSON.stringify({ venue, userId, playerId, intentId, tableNumber }),
    headers: { 'Content-Type': 'application/json' }
  })

/*
Create payment intent with Stripe
*/
export const createPaymentIntent = (venueId, amount, currency, payment_method_types, stripeId, sms) =>
  __fetch(['payment', venueId, 'intent'], {}, {
    method: 'POST',
    body: JSON.stringify({ amount, currency, payment_method_types, stripeId, sms }),
    headers: { 'Content-Type': 'application/json' }
  })
    .then(
      ({ id, secret }) => {
        return { id, secret }
      }
    )

export const cancelPaymentIntent = (venueId, intent) =>
  __fetch(['payment', venueId, 'intent', 'cancel'], {}, {
    method: 'POST',
    body: JSON.stringify({ intent }),
    headers: { 'Content-Type': 'application/json' }
  })
    .then(({ intent, status }) => {
      return { intent, status }
    })

export const getStripeKey = (venueId) =>
  __fetch(['payment', venueId, 'key'], {}, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })

export const addProduct = (orderId, product, subProducts, instructions, qty, net_price) =>
  __fetch(['order', orderId], {}, {
    method: 'PUT',
    body: JSON.stringify({ product, subProducts, instructions, qty, net_price }),
    headers: { 'Content-Type': 'application/json' }
  })

export const addNote = (orderId, note) =>
  __fetch(['order', orderId, 'update', 'note'], {}, {
    method: 'PUT',
    body: JSON.stringify({ note: note }),
    headers: { 'Content-Type': 'application/json' }
  })


export const submitOrder = (orderId) => __fetch(['order', orderId, 'status', 'submit'], {}, { method: 'PUT' })


export const createAndSubmitOrder = (userId, intentId, venue, playerId = '', tableNumber, products = {}, costs = [], note) =>
  createOrder(userId, intentId, venue, playerId, tableNumber)
    .then(
      ({ id }) => Promise.all(
        Object.entries(products).map(
          ([product, { quantity, subProducts, instructions, net_price }]) => addProduct(id, product, subProducts, instructions, quantity, net_price)
        )
      )
        .then(
          Promise.all(costs.filter(c => c.quantity > 0).map(c => addProduct(id, c.id, null, null, c.quantity, c.net_price)))
        )
        .then(
          () => {
            note ? addNote(id, note) : Promise.resolve()
          }
        )
        .then(
          () => submitOrder(id)
        )
        .then(
          ({ passphrase }) => ({ id, passphrase })
        )
    )

export const suspendOrder = (orderId) => __fetch(['order', orderId, 'status', 'hold'], {}, { method: 'PUT' })

export const deliverOrder = (orderId) => __fetch(['order', orderId, 'status', 'delivered'], {}, { method: 'PUT' })

export const modifyOrder = (orderId, products) =>
  __fetch(['order', orderId, 'product'], {}, { method: 'DELETE' })
    .then(
      () => Promise.all(Object.entries(products).map(([product, { quantity, subProducts, instructions, price }]) => addProduct(orderId, product, subProducts, instructions, quantity, price)))
    )

export const getUpdatedOrder = (venueId, orderId) => __fetch(['order', venueId, 'updatedOrder', orderId], {}, { method: 'GET' })
  .then(order => order)

export const deleteOrder = (orderId) => __fetch(['order', orderId], {}, { method: 'DELETE' })

export const getPositionInQueue = (venueId, orderId) => __fetch(['order', venueId, orderId, 'queue']).then(({ position }) => position)

export const getUserOrder = (userId) => __fetch(['order', 'user', userId])
