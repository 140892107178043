import React, { useContext } from 'react'
import formatCurrency from '../../../helpers/formatCurrency'
import { t } from '../../../i18n'
import plus from '../../../images/plusbutton.png'
import minus from '../../../images/minusbutton.png'
import { VenueContext } from '../../../stores/VenueProvider'
import { OrderContext } from '../../../stores/OrderProvider'
import './OrderList.css'


const OrderRow = ({ identifier, product, subProducts, quantity, editable }) => {
    const orderStore = useContext(OrderContext)
    const venueStore = useContext(VenueContext)
    const getBundlePrice = (product, subProducts, index) => {
        if (product.net_price > 0) {
            return formatCurrency(product.net_price, venueStore.venue.currency)
        }
        var total = 0
        let sp = subProducts[index]
        for (let el of sp) {
            total += Object.values(el).map(({ price }) => price).reduce((sum, price) => sum + price, 0)
        }
        return formatCurrency(total, venueStore.venue.currency)
    }

    return (
        subProducts && subProducts.length > 0 ?
            subProducts.map((sps, index) =>
                <div className="row-product" key={identifier}>
                    <div className="row-products">
                        <div className="row-product-quantity-name">{'1 x ' + product.name || ''}</div>
                        <div className="row-subproducts">{t('Order.including')}</div>
                        {
                            sps.map(sp =>
                                Object.entries(sp).map(([key, value]) =>
                                    <div className="row-subproduct-quantity-name" key={key}>{value.quantity + ' x ' + value.name || ''}</div>
                                )
                            )
                        }
                    </div>
                    <div className="row-right">
                        <div className="row-product-price">{getBundlePrice(product, subProducts, index)}</div>
                        {
                            editable ?
                                <div className="button-modifiers">
                                    <input type="image" src={minus} onClick={() => orderStore.updateOrder(product.id, sps, null, quantity - 1, product.net_price)} alt="Minus" width="26" height="26" />
                                    <input type="image" src={plus} onClick={() => orderStore.updateOrder(product.id, sps, null, quantity + 1, product.net_price)} alt="Plus" width="26" height="26" />
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            )
            :
            <div className="row-product" key={identifier}>
                <div className="row-products">
                    <div className="row-product-quantity-name">{quantity + ' x ' + product.name || ''}</div>
                </div>
                <div className="row-right">
                    <div className="row-product-price">
                        {formatCurrency(product.net_price * quantity, venueStore.venue.currency)}
                    </div>
                    {
                        editable ?
                            <div className="button-modifiers">
                                <input type="image" src={minus} onClick={() => orderStore.updateOrder(product.id, product.subProducts, product.instructions, quantity - 1, product.net_price)} alt="Minus" width="26" height="26" />
                                <input type="image" src={plus} onClick={() => orderStore.updateOrder(product.id, product.subProducts, product.instructions, quantity + 1, product.net_price)} alt="Plus" width="26" height="26" />
                            </div>
                            :
                            null
                    }
                </div>
            </div>
    )
}

const CostRow = ({ identifier, product, quantity }) => {
    const orderStore = useContext(OrderContext)
    const venueStore = useContext(VenueContext)
    return (
        <div className="row-product" key={identifier}>
            <div className="row-costs">
                <div className="row-product-quantity-name">{quantity + ' x ' + product.name || ''}</div>
                <div className="row-cost">{product.description || ''}</div>
            </div>
            <div className="row-right">
                <div className="row-product-price">
                    {formatCurrency(product.net_price * product.quantity, venueStore.venue.currency)}
                </div>
                {
                    product.manual === 1 ?
                        <div className="button-modifiers">
                            <input type="image" src={minus} onClick={() => orderStore.updateCost(product.id, quantity - 1)} alt="Minus" width="26" height="26" />
                            <input type="image" src={plus} onClick={() => orderStore.updateCost(product.id, quantity + 1)} alt="Plus" width="26" height="26" />
                        </div>
                        :
                        null
                }
            </div>
        </div>
    )
}

export default ({ order, products, editable }) => {
    const orderStore = useContext(OrderContext)
    const venueStore = useContext(VenueContext)
    const hasCosts = orderStore.costs(order.products).length

    return (
        <div className="order-content">
            <div className="order-list">
                {
                    Object.entries(order.products || {}).map(([productId, { quantity, subProducts, price }]) =>
                        <OrderRow key={productId} identifier={productId} product={products[productId]} subProducts={subProducts} quantity={quantity} editable={editable} />
                    )
                }
            </div>
            <div className="order-articles">{t('Order.articles', { quantity: Object.values(order.products).reduce((sum, { quantity = 0, bundleId }) => sum + quantity, 0) })}</div>
            {
                hasCosts ?
                    <div className="order-costs">
                        <div className="order-costs-title">{t('Order.costs')}</div>
                        <div className="order-costs-list">
                            {
                                orderStore.costs(order.products).map(cost => <CostRow key={cost.uuid} identifier={cost.uuid} product={cost} quantity={cost.quantity} />)
                            }
                        </div>
                    </div>
                    :
                    null
            }
            <div className="order-total">
                <div style={{marginRight: '5px'}}>{t('Order.total')}</div>
                {formatCurrency(order.total, venueStore.venue.currency)}
            </div>
        </div>
    )
}