import io from 'socket.io-client'

class IO {
    constructor(url) {
        this.socket = null
        this.venue = null
        this.url = url
        this.callbackId = 0
        this.callbacks = {}
    }

    on(eventName, callback) {
        this.socket ? this.checkVenue() : this.connect()

        if (!this.callbacks[eventName]) {
            this.callbacks[eventName] = {}
        }

        const callbackId = callback.name
        this.callbacks[eventName][callbackId] = callback

        // return unsubscribe method
        return () => delete this.callbacks[eventName][callbackId]
    }

    off(eventName, callback) {
        this.socket ? this.checkVenue() : this.connect()

        if (!this.callbacks[eventName]) return;

        const callbackId = ++this.callbackId + ''
        this.socket.off(eventName, callback)
        delete this.callbacks[eventName][callbackId]

    }

    runCallbacks(eventName, data) {
        Object.values(this.callbacks[eventName] || {}).forEach(
            callback => callback && callback(data)
        )
    }

    checkVenue(venue) {
        if (this.venue !== venue) {
            this.socket.disconnect()
            this.connect()
        }
    }

    connect() {
        var localStorage = require('local-storage')
        const venue = JSON.parse(localStorage.get('venue'))
        
        this.venue = venue ? venue.id : null

        this.socket = io(this.url, {
            transports: ['websocket'],
            jsonp: false
        })

        this.socket.emit('customer:join', { venue: this.venue })

        this.socket.on('customer:joined', () => {
            console.log('CUSTOMER JOINED')
        })

        this.socket.on('queue:update', queue => {
            this.runCallbacks('queue:update', queue)
        })

        this.socket.on('section:update', section => {
            this.runCallbacks('section:update', section)
        })

        this.socket.on('category:update', category => {
            this.runCallbacks('category:update', category)
        })

        this.socket.on('product:update', product => {
            this.runCallbacks('product:update', product)
        })

        /**
         * Orders management
         *
         * Here's how we deal with orders' updates
         */
        this.socket.on('order:update', order => this.runCallbacks('order:update', order))
        this.socket.on('order:cancel', order => this.runCallbacks('order:cancel', order))
    }
}

export const ws = new IO(window._env_.REACT_APP_RT_URL, { secure: !!window._env_.REACT_APP_RT_SECURE })