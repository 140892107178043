import React from 'react'

const style = {
    border: "1px solid",
    borderRadius: "20px",
    borderColor: "gray",
    margin: "20px 30px",
    fontWeight: "bold",
    padding: "10px",
    textAlign: "center"
}

export default (props) => {
    return (
        <div style = {style}>{props.passphrase}</div>
    )
}