import React, { useContext } from 'react'
import Modal from '../../../components/Modal/Modal'
import { OrderContext } from '../../../stores/OrderProvider'
import plus from '../../../images/plusbutton.png'
import minus from '../../../images/minusbutton.png'
import { t } from '../../../i18n'
import './CostsModal.css'
import close from '../../../images/closebutton.png'

export default props => {

    const { product = {}, isVisible = false, onClose = () => { } } = props
    const orderStore = useContext(OrderContext)

    return (
        product ?
            <Modal isVisible={isVisible} width="300px" effect="fadeInUp" onClickAway={() => onClose()}>
                <div className="costContainer">
                    <div className="costClose">
                        <input type="image" src={close} onClick={onClose} alt="Close" width="20" height="20" />
                    </div>
                    <div className="costInfo">
                        <div className="costName">{product.name}</div>
                        <div className="costDesc">{product.message}</div>
                        <div className="costAmount">
                            <input type="image" src={minus} onClick={() => orderStore.updateCost(product.id, (product.quantity || 0) - 1)} alt="Minus" width="30" height="30" />
                            <div className="amount">{product.quantity || 0}</div>
                            <input type="image" src={plus} onClick={() => orderStore.updateCost(product.id, (product.quantity || 0) + 1)} alt="Plus" width="30" height="30" />
                        </div>
                    </div>
                    <button className="costContainerButton" onClick={onClose}>{t("Menu.go")}</button>
                </div>
            </Modal>
            :
            <></>
    )
}