import React, { useState, useEffect, useContext } from 'react'
import Categories from './components/Categories'
import ProductList from './components/ProductList'
import Cart from './components/Cart'
import Queue from './components/Queue'
import Footer from '../../components/Footer'
import { VenueContext } from '../../stores/VenueProvider'
import { OrderContext } from '../../stores/OrderProvider'
import { Link, useHistory, useLocation } from 'react-router-dom'
import back from '../../images/back.png'
import './Menu.css'
import Dropdown from 'react-dropdown'
import { changeLanguage } from '../../i18n'
import * as API from '../../api/api.js'
import { ws } from '../../io/io'

export default (props) => {

    const venueStore = useContext(VenueContext)
    const orderStore = useContext(OrderContext)

    const expiringStorage = require('../../helpers/expiringStorage')

    const [selected, setSelected] = useState('')
    const [categories, setCategories] = useState([])
    const [payment, setPayment] = useState(false)
    const [image, setImage] = useState('')
    const [title, setTitle] = useState('')
    const [menuId, setMenuId] = useState('')
    const [tableService, setTableService] = useState(0)
    const [selectedLanguage, setSelectedLanguage] = useState({})

    const history = useHistory()

    var localStorage = require('local-storage')

    const listEnabled = venueStore.venue.wishlist
    let location = useLocation()

    const updateCategories = data => {
        let current = props.location.state ? props.location.state.menu : venueStore.currentMenu
        let menu = orderStore.sections.find(s => s.id === current.id)
        let category = menu.categories.find(c => c.uuid === data.category.id)
        if (category) {
            for (let key in data.category) {
                if (key === "languages") {
                    let language = data.category[key].find(l => l.locale.slice(-2).toUpperCase() === localStorage.get("language"))
                    category.name = language ? language.name : data.category.name
                    category.tagline = language ? language.tagline : data.category.tagline
                } else {
                    category[key] = data.category[key]
                }
            }
            orderStore.setSections(orderStore.sections)
            setCategories(menu.categories.filter(c => !c.unavailable && c.products && c.products.length > 0).sort((a, b) => a.position - b.position))
            venueStore.setCurrentMenu(menu)
        }
    }

    const updateProducts = data => {
        let current = props.location.state ? props.location.state.menu : venueStore.currentMenu
        let menu = orderStore.sections.find(s => s.id === current.id)
        let category = menu.categories.find(c => c.products.some(p => p.id === data.product.id))
        let product = category.products.find(p => p.id === data.product.id)
        if (product) {
            for (let key in data.product) {
                if (key === "languages") {
                    let language = data.product[key].find(l => l.locale.slice(-2).toUpperCase() === localStorage.get("language"))
                    product.name = language ? language.name : data.product.name
                    product.description = language ? language.description : data.product.description
                } else {
                    product[key] = data.product[key]
                }
            }
            orderStore.setSections(orderStore.sections)
            setCategories(menu.categories.filter(c => !c.unavailable && c.products && c.products.length > 0))
            venueStore.setCurrentMenu(menu)
        }
    }

    useEffect(() => {
        const menu = props.location.state ? props.location.state.menu : venueStore.currentMenu
        venueStore.setCurrentMenu(menu)
        if (localStorage.get('language') === 'AR' || localStorage.get('language') === 'IL') {
            document.documentElement.dir = 'rtl'
        } else {
            document.documentElement.dir = 'ltr'
        }
        setSelectedLanguage({
            label: <img className='dropdown-flag' src={require(`../../images/flags/${localStorage.get("language")}.svg`)} height={12} />,
            value: localStorage.get("language"),
        })

        var start, end;
        setSelected(0)
        setPayment(menu.payment)
        setImage(menu.image)
        setTitle(menu.name)
        setMenuId(menu.id)
        setTableService(menu.table_service)
        setCategories(menu.categories.filter(c => !c.unavailable && c.products && c.products.length > 0))

        const offset = expiringStorage.get('offset')
        if (offset != null) {
            document.addEventListener('scroll', trackScrolling)
            window.scrollTo({ top: offset, behavior: 'auto' })
            setTimeout(() => {
                if (document.getElementById("categories-container") && expiringStorage.get('selectedCategory')) {
                    document.getElementById("categories-container").scrollTo({ left: document.getElementById("cat" + expiringStorage.get('selectedCategory')).offsetLeft - 10, behavior: "auto" })
                }
            }, 50)
            setSelected(expiringStorage.get('selectedCategory'))
            expiringStorage.remove('offset')
        }
        else {
            // Avoid blocking the UI for large menus
            setTimeout(() => {
                document.addEventListener('scroll', trackScrolling)
            }, 200)
        }

        ws.on('category:update', updateCategories)
        ws.on('product:update', updateProducts)

        return () => {
            clearTimeout(start)
            clearTimeout(end)
            document.removeEventListener('scroll', trackScrolling)
            ws.off('category:update')
            ws.off('product:update')
        }
    }, [])


    const trackScrolling = () => {
        let menuTop = document.getElementById("menu-top")
        let top = menuTop.getBoundingClientRect().top
        if (top <= 40) {
            menuTop.style.borderRadius = top + "px " + top + "px 0px 0px"
        }

        const menu = props.location.state ? props.location.state.menu : venueStore.currentMenu
        menu.categories.filter(c => !c.unavailable && c.products && c.products.length > 0).forEach((c, id) => {
            if (document.getElementById(c.id)) {
                let topCat = document.getElementById(c.id).getBoundingClientRect().top
                if (topCat >= 100 && topCat <= 140) {
                    setSelected(id)
                    expiringStorage.set('selectedCategory', id, 60000)
                    // clearTimeout(scrl)
                    let scrl = setTimeout(() => {
                        if (document.getElementById("categories-container") && document.getElementById("cat" + id)) {
                            document.getElementById("categories-container").scrollTo({ left: document.getElementById("cat" + id).offsetLeft - 10, behavior: "smooth" })
                        }
                    }, 750)
                }
            }
        })
    }

    const flags = (venueStore.venue.supportedLanguages || []).map((language) => ({
        label: <img className='dropdown-flag' src={require(`../../images/flags/${language}.svg`)} height={12} />,
        value: language,
    }))

    const updateLanguage = (option) => {
        let selected = option.value
        setSelectedLanguage(option)
        if (selected === "AR" || selected === "IL") {
            document.documentElement.dir = "rtl"
        } else {
            document.documentElement.dir = "ltr"
        }
        localStorage.set("language", selected)
        changeLanguage(selected)
        // English needs special handling
        if (selected === 'GB') { selected = 'EN' }
        API.getMenu(venueStore.venue.id, selected.toLowerCase() + "-" + option.value).then(({ sections, instructions, queue, costs }) => {
            if (instructions) {
                orderStore.setInstructions(instructions);
            }
            venueStore.setQueue(queue);
            if (sections) {
                orderStore.setSections(sections);
                if (location.pathname.includes('/table')) {
                    orderStore.setCosts(costs.filter(c => c.table === true));
                } else {
                    orderStore.setCosts(costs.filter(c => c.bar === true));
                }
                let section = sections.find(s => s.id === venueStore.currentMenu.id)
                venueStore.setCurrentMenu(section)
                setTitle(section.name)
                setCategories(section.categories)
            }
        })
    };

    return (
        <div className="menu-transition">
            <div className="menu-image">
                <img src={image} style={{ objectFit: 'cover' }} height={'230px'} width={'100%'} alt='' />
            </div>
            <div id="menu-page" className="menu-page">
                <div className="menu-container">
                    <div style={{ height: '190px', width: '100%', backgroundColor: 'transparent' }}>
                        <div className={"menu-back-" + document.documentElement.dir} onClick={history.goBack}><img src={back} alt="" height={30} width={30} /></div>
                    </div>
                    {(venueStore.venue.supportedLanguages || []).length > 1 ?
                        <div className="menu-header">
                            <div id="menu-top" className="menu-gutter cst-_menu-gutter cst-global-_menu-gutter">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                    <div style={{ width: "50px" }} />
                                    <div className={`menu-gutter-title cst-_menu-gutter-title cst-global-_menu-gutter-title cst-item-${menuId}`} style={{ position: 'relative', textAlign: 'center' }} data-cst="cst">{title}</div>
                                    <Dropdown
                                        options={flags}
                                        onChange={(option) => updateLanguage(option)}
                                        value={selectedLanguage}
                                        placeholder={<img className='dropdown-flag' src={require("../../images/flags/FR.svg")} height={12} />}
                                    />
                                </div>
                                {!!payment && <Queue initialCount={venueStore.queue} />}
                            </div>
                            <Categories categories={categories} selected={selected} onSelect={id => {
                                setSelected(id)
                                expiringStorage.set('selectedCategory', id, 60000)
                                window.scrollTo({ top: (document.getElementById(categories[id].id).offsetTop + 190), behavior: 'smooth' })
                            }} menuId={menuId} />
                        </div>
                        :
                        <div className="menu-header">
                            <div id="menu-top" className="menu-gutter cst-_menu-gutter cst-global-_menu-gutter">
                                <div className={`menu-gutter-title cst-_menu-gutter-title cst-global-_menu-gutter-title cst-item-${menuId}`} style={{ position: 'relative', textAlign: 'center' }} data-cst="cst">{title}</div>
                                {!!payment && <Queue initialCount={venueStore.queue} />}
                            </div>
                            <Categories categories={categories} selected={selected} onSelect={id => {
                                setSelected(id)
                                expiringStorage.set('selectedCategory', id, 60000)
                                window.scrollTo({ top: (document.getElementById(categories[id].id).offsetTop + 190), behavior: 'smooth' })
                            }} menuId={menuId} />
                        </div>
                    }
                    <ProductList categories={categories} menuId={menuId} />
                    <Footer venue={venueStore.venue} />
                </div>
                {
                    (!!payment || !!listEnabled) &&
                    <Link to={{
                        pathname: "/order",
                        state: {
                            table_service: tableService || orderStore.order.table != null,
                        }
                    }}>
                        <Cart count={orderStore.order.count} total={orderStore.order.total} />
                    </Link>
                }
            </div>
        </div>
    )
}