import React, { createContext, Component } from 'react'

export const OrderContext = createContext({
  order: {},
  sections: [],
  products: {},
  instructions: {},
  summary: {},
  setSections: () => { },
  updateOrder: () => { },
  setNote: () => { },
  setSummary: () => { },
  cleanUp: () => { },
  setCosts: () => { },
  costs: () => { },
  updateCost: () => { },
  updateProductCost: () => { },
  enableTableInput: () => { }
})

var localStorage = require('local-storage')
var _ = require('lodash')

class OrderProvider extends Component {

  state = {
    sections: this.defaultSections(),
    products: this.defaultProducts(),
    instructions: this.defaultInstructions(),
    order: this.defaultOrder(),
    summary: this.summary(),
    setSections: sections => {
      localStorage.set('sections', JSON.stringify(sections))
      let prdcts = {}
      sections.forEach(section => section.categories.forEach(category => category.products.forEach(p => prdcts[p.id] = { ...p, costAmount: 0 })))
      let bundles = Object.values(prdcts).filter(p => p.type_id === 2 || p.type_id === 3)
      let cats = bundles.map(b => b.bundle_categories)
      let sprdcts = [].concat.apply([], [].concat.apply([], cats).map(c => c.products))
      sprdcts.forEach(p => {
        if (!prdcts[p.id]) {
          prdcts[p.id] = { ...p, type_id: 1, recipe: p.description, costAmount: 0, recommendations: [] }
        }
      })
      localStorage.set('products', JSON.stringify(prdcts))
      this.setState({ sections: sections, products: prdcts })
    },
    setInstructions: instructions => {
      localStorage.set('instructions', JSON.stringify(instructions))
      this.setState({ instructions: instructions })
    },
    updateOrder: (productId, subProducts, instructions, quantity, net_price, costQuantity) => {
      let orderProducts = this.state.order.products
      var total = 0
      if (subProducts && subProducts.length) {
        if (orderProducts[productId] && orderProducts[productId].subProducts) {
          if (quantity > orderProducts[productId].quantity) {
            orderProducts[productId].subProducts.push(subProducts)
          } else {
            orderProducts[productId].subProducts.splice(orderProducts[productId].subProducts.findIndex(sp => sp === subProducts), 1)
          }
          orderProducts[productId].quantity = quantity
        } else {
          orderProducts = { ...this.state.order.products, [productId]: { quantity: quantity, subProducts: [subProducts], net_price: net_price, costAmount: costQuantity } }
        }
      } else {
        this.state.order.costs.forEach(c => { if (c.id === this.state.products[productId].cost_id) c.quantity = c.quantity + costQuantity })
        let entries = Object.entries(instructions || {})
        orderProducts = {
          ...this.state.order.products,
          [productId]: { quantity: quantity, subProducts: null, instructions: entries.length ? entries.map(([key, value]) => value) : null, net_price: net_price, costAmount: costQuantity },
        }
      }

      total += Object.entries(orderProducts).map(([id, { quantity, subProducts, instructions, net_price }]) => net_price * (quantity || 0))
        .reduce((sum, net_price) => sum + net_price, 0)

      let sps = []
      Object.entries(orderProducts).forEach(([id, { quantity, subProducts, instructions, net_price }]) => (subProducts && subProducts.length ? sps.push(...subProducts) : null))
      for (let sp of sps) {
        for (let el of sp) {
          total += Object.values(el).map(({ quantity, name, net_price}) => net_price).reduce((sum, net_price) => sum + net_price, 0)
        }
      }

      //In case all products are removed, also clean up potentially remaining costs
      if (total === 0 && this.state.order.costs && this.state.order.costs.length) {
        let order = this.state.order
        order.costs.forEach(c => {
          if (c.application !== 1) {
            c.quantity = 0
          }
        })
      }

      let totalCosts = this.costs(orderProducts).map(cost => cost.net_price * cost.quantity).reduce((a, b) => a + b, 0)
      let count = Object.values(orderProducts).reduce((sum, { quantity = 0, subProducts }) => sum + quantity, 0)
      let updatedOrder = {
        ...this.state.order,
        costs: this.state.order.costs,
        total: parseFloat((total + totalCosts).toFixed(2)),
        count: count,
        products: {
          ...orderProducts
        }
      }
      if (!quantity) {
        delete updatedOrder.products[productId]
      }
      localStorage.set('order', JSON.stringify(updatedOrder))
      this.setState({ order: updatedOrder })
    },
    setSummary: (summary) => {
      localStorage.set('summary', JSON.stringify(summary))
      this.setState({ summary: summary })
    },
    setOrderId: (id) => {
      let updatedOrder = {
        ...this.state.order,
        id: id
      }
      localStorage.set('order', JSON.stringify(updatedOrder))
      this.setState({ order: updatedOrder })
    },
    replaceOrder: (products, total) => {
      let count = Object.values(products).reduce((sum, { quantity = 0, subProducts, net_price }) => sum + quantity, 0)
      let updatedOrder = {
        ...this.state.order,
        total: parseFloat(total.toFixed(2)),
        count: count,
        products: products
      }

      localStorage.set('order', JSON.stringify(updatedOrder))
      this.setState({ order: updatedOrder })
    },
    setPassphrase: (passphrase) => {
      let updatedOrder = {
        ...this.state.order,
        passphrase: passphrase
      }
      localStorage.set('order', JSON.stringify(updatedOrder))
      this.setState({ order: updatedOrder })
    },
    enableTableInput: (enabled) => {
      let updatedOrder = {
        ...this.state.order,
        tableInput: enabled
      }
      localStorage.set('order', JSON.stringify(updatedOrder))
      this.setState({ order: updatedOrder })
    },
    setTable: (table) => {
      let updatedOrder = {
        ...this.state.order,
        table: table
      }
      localStorage.set('order', JSON.stringify(updatedOrder))
      this.setState({ order: updatedOrder })
    },
    setNote: (note) => {
      let updatedOrder = {
        ...this.state.order,
        note: note
      }
      localStorage.set('order', JSON.stringify(updatedOrder))
      this.setState({ order: updatedOrder })
    },
    setIntent: (intent) => {
      let updatedOrder = {
        ...this.state.order,
        intent: intent
      }
      localStorage.set('order', JSON.stringify(updatedOrder))
      this.setState({ order: updatedOrder })
    },
    setStatus: (status) => {
      let updatedOrder = {
        ...this.state.order,
        status: status
      }
      localStorage.set('order', JSON.stringify(updatedOrder))
      this.setState({ order: updatedOrder })
    },
    cleanUp: () => {
      const table = this.state.order.table
      localStorage.remove('sections')
      localStorage.remove('products')
      localStorage.remove('instructions')
      localStorage.remove('order')
      this.setState({ products: {}, order: { id: null, total: 0, count: 0, table: table, tableInput: true, products: {}, status: 'new', passphrase: '', intent: null, costs: null, note: null } })
    },
    setCosts: (costs) => {
      if (_.isEqual(this.state.order.costs, costs)) return;
      let updatedOrder = {
        ...this.state.order,
        costs: costs
      }
      localStorage.set('order', JSON.stringify(updatedOrder))
      this.setState({ order: updatedOrder })
    },
    updateCost: (costId, quantity) => {
      if (quantity <= 0) quantity = 0
      let orderProducts = this.state.order.products
      let costs = this.state.order.costs.filter(cost => cost.manual === 1)
      let productsTotal = Object.entries(orderProducts).map(([id, { quantity, subProducts, net_price }]) => net_price * (quantity || 0))
        .reduce((sum, net_price) => sum + net_price, 0)
      let costsTotal = this.costs(orderProducts).filter(c => c.manual === 0).map(cost => cost.net_price * cost.quantity).reduce((a, b) => a + b, 0)
      let total = productsTotal + costsTotal
      if (costs.length) {
        costs.forEach(cost => {
          if (cost.id === costId) {
            if (cost.threshold > 0 && quantity <= cost.threshold) {
              cost.quantity = quantity
              total += cost.quantity * cost.net_price
            } else {
              cost.quantity = cost.threshold
              total += cost.quantity * cost.net_price
            }
          }
        })

        if (quantity === 0) {
          delete costs[costs.findIndex(c => c.id === costId)]
        }

        if (total >= 0) {
          let updatedOrder = {
            ...this.state.order,
            costs: this.state.order.costs,
            total: parseFloat(total.toFixed(2))
          }
          localStorage.set('order', JSON.stringify(updatedOrder))
          this.setState({ order: updatedOrder })
        }
      }
    },
    updateProductCost: (product, amount) => {
      let orderProducts = this.state.order.products
      if (orderProducts[product.id]) {
        orderProducts[product.id].costAmount = amount > 0 ? amount : 0
        let cost = this.state.order.costs.filter(c => c.id === product.cost_id)[0]
        cost.quantity = amount > 0 ? amount : 0
        let productsTotal = Object.entries(orderProducts).map(([id, { quantity, subProducts, net_price }]) => net_price * (quantity || 0))
          .reduce((sum, net_price) => sum + net_price, 0)
        let costsTotal = this.costs(orderProducts).filter(c => c.manual === 0).map(cost => cost.net_price * cost.quantity).reduce((a, b) => a + b, 0)
        let total = productsTotal + costsTotal
        let updatedOrder = {
          ...this.state.order,
          costs: this.state.order.costs,
          total: parseFloat(total.toFixed(2))
        }
        localStorage.set('order', JSON.stringify(updatedOrder))
        this.setState({ order: updatedOrder })
      }
    },
    costs: (products) => this.costs(products),
  }

  defaultOrder() {
    const storedOrder = JSON.parse(localStorage.get('order'))
    return storedOrder ? storedOrder : { id: null, total: 0, count: 0, table: null, tableInput: true, products: {}, status: 'new', passphrase: '', intent: null, costs: null, note: null }
  }

  defaultSections() {
    const storedSections = JSON.parse(localStorage.get('sections'))
    return storedSections ? storedSections : []
  }

  defaultProducts() {
    const storedProducts = JSON.parse(localStorage.get('products'))
    return storedProducts ? storedProducts : {}
  }

  defaultInstructions() {
    const storedInstructions = JSON.parse(localStorage.get('instructions'))
    return storedInstructions ? storedInstructions : {}
  }

  summary() {
    const storedSummary = JSON.parse(localStorage.get('summary'))
    return storedSummary ? storedSummary : {}
  }

  costs = (products) => {
    let costs = []
    if (this.state.order.costs) {
      //Venue
      costs = this.state.order.costs.filter(cost => cost.application === 1).map(cost => ({ ...cost, quantity: 1 }))
      if (products) {
        //Menu
        let menuCosts = this.state.order.costs.filter(cost => cost.application === 2).map(cost => ({ ...cost, quantity: cost.quantity || 0 }))
        if (menuCosts) {
          let orderProducts = Object.entries(products)

          for (let cost of menuCosts) {
            for (let [id, { quantity, subProducts }] of orderProducts) {
              let sct = this.state.sections.filter(s => s.id === cost.sections)[0]
              if (sct && sct.categories.some(c => c.products.some(p => p.id === id)) && !cost.manual) {
                if (cost.multiple) {
                  cost.quantity += quantity
                } else {
                  cost.quantity = 1
                  break
                }
              }
            }
          }
          costs = costs.concat(menuCosts.filter(c => c.quantity > 0))
        }
        //Category
        let categoryCosts = this.state.order.costs.filter(cost => cost.application === 3).map(cost => ({ ...cost, quantity: cost.quantity || 0 }))
        if (categoryCosts) {
          let pdcts = this.state.products
          let orderProducts = Object.entries(products)

          for (let cost of categoryCosts) {
            for (let [id, { quantity, subProducts }] of orderProducts) {
              if (pdcts[id].category_id.includes(cost.category_id) && !cost.manual) {
                if (cost.multiple) {
                  cost.quantity += quantity
                } else {
                  cost.quantity = 1
                  break
                }
              }
            }
          }
          costs = costs.concat(categoryCosts.filter(c => c.quantity > 0))
        }
      }
    }
    return costs
  }

  render() {
    return (
      <OrderContext.Provider value={this.state}>
        {this.props.children}
      </OrderContext.Provider>
    )
  }
}

export default OrderProvider

export const withStore = Component => props => (
  <OrderContext.Consumer>
    {store => <Component {...props} {...store} />}
  </OrderContext.Consumer>
)