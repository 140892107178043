import React, { createContext, Component } from "react";

export const VenueContext = createContext({
  venue: {},
  setVenue: () => {},
  queue: 0,
  setQueue: () => {},
  // tags: {},
  // setTags: () => {},
  // banners: {},
  // setBanners: () => {},
});

var localStorage = require("local-storage");

const checkStorage = key => {
  let cached = localStorage.get(key)
  if (cached && cached != 'undefined') {
    return cached
  }
  return null
}

class VenueProvider extends Component {
  state = {
    venue: checkStorage("venue") ? JSON.parse(localStorage.get("venue")) : {},
    setVenue: (venue) => {
      localStorage.set("venue", JSON.stringify(venue));
      this.setState({ venue: venue });
    },
    resetVenue: () => {
      localStorage.remove("venue");
      localStorage.remove("currentMenu");
      this.setState({ venue: {} });
    },
    currentMenu: checkStorage("currentMenu") ? JSON.parse(localStorage.get("currentMenu")) : {},
    setCurrentMenu: (menu) => {
      localStorage.set("currentMenu", JSON.stringify(menu));
      this.setState({ currentMenu: menu });
    },
    queue: 0,
    setQueue: (queue) => {
      this.setState({ queue: queue });
    },
    // tags: localStorage.get("tags")? JSON.parse(localStorage.get("tags")) : {},
    // setTags: (tags) => {
    //   localStorage.set("tags", JSON.stringify(tags));
    //   this.setState({ tags: tags });
    // },
    // banners: localStorage.get("banners") ? JSON.parse(localStorage.get("banners")) : {},
    // setBanners: (banners) => {
    //   localStorage.set("banners", JSON.stringify(banners));
    //   this.setState({ banners: banners });
    // },
  };

  render() {
    return <VenueContext.Provider value={this.state}>{this.props.children}</VenueContext.Provider>;
  }
}

export default VenueProvider;

export const withVenue = (Component) => (props) => <VenueContext.Consumer>{(store) => <Component {...props} {...store} />}</VenueContext.Consumer>;
