import React from "react";

export default (props) => {
  const { categories, selected, onSelect, menuId } = props;

  return (
    <div id='categories-container' className='categories-container cst-categories-container cst-global-categories-container'>
      {(categories || []).map((category, index) =>
        (category.products || []).filter((p) => !p.unavailable).length ? (
          <div
            id={"cat" + index}
            key={category.uuid}
            className={`category cst-_category cst-menu-${menuId} cst-item-${category.uuid} ${selected === index ? "selected" : ""}`}
            data-cst='cst'
            style={{ position: "relative" }}
            onClick={() => onSelect(index)}
          >
            <span style={{pointerEvents:"none"}}>{category.name}</span>
          </div>
        ) : null
      )}
      <div className='' style={{ opacity: 0, padding: "5px" }} />
    </div>
  );
};
