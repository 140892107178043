import {
  APPERO_WEB_CHANGE_SUCCESSFUL,
  APPERO_WEB_SELECTOR,
  CANCEL_SAVE_CURRENT_STYLE,
  CHANGE_SELECTOR,
  CHANGE_SELECTOR_PERIMETER,
  CHANGE_STYLE,
  CUSTOM_STYLESHEET_ID,
  ENTER_SELECT_MODE,
  HIDE_HIDDEN_ELEMENTS,
  PREPARE_IFRAME,
  QUIT_SELECT_MODE,
  RESET_ALL_STYLES,
  RESTORE_STYLES,
  SAVE_CURRENT_STYLES_REQUEST,
  SHOW_HIDDEN_ELEMENTS,
} from "./constant";

const config = {
  REACT_APP_STYLE_URL: window._env_.REACT_APP_STYLE_URL,
  STYLESHEET_STORAGE_PATH: window._env_.STYLESHEET_STORAGE_PATH,
};

export const styleSelector = () => {
  let source;
  let elementSelectedStylesheet = document.createElement("style");
  elementSelectedStylesheet.id = "SELECTOR";
  document.head.appendChild(elementSelectedStylesheet);

  let beforeChangesStyles = [];

  let beforeChangesSavedStylesheet;
  let customStylesheet;

  let fontImportStylesheet = document.createElement("style");
  fontImportStylesheet.id = "FONT_FACE_IMPORTS";
  let fontFaceImports = [];

  document.head.appendChild(fontImportStylesheet);

  const sendClickedSelector = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!source) {
      return;
    }

    if (!e.target.dataset || !e.target.dataset.cst || e.target.dataset.cst !== "cst") {
      return;
    }

    const cstClassList = e.target.classList.value.split(" ").filter((className) => className.includes("cst"));

    const classSelector = `.${cstClassList.join(".")}`;
    let styles;
    if (classSelector.includes(".cst-image-product-list") || classSelector.includes(".cst-product-image-fp")) {
      console.log("ok");
      styles = `${classSelector} {opacity:0.5}`;
    } else {
      styles = `${classSelector}::before {content:"";position:absolute;top:-3px;bottom:-3px;left:-3px;right:-3px;border:3px solid lightblue;border-radius:5px;visibility:visible; opacity:1}`;
    }

    elementSelectedStylesheet.innerText = styles;

    source.postMessage({ type: APPERO_WEB_SELECTOR, selector: classSelector }, "*");
  };

  window.onmessage = function(event) {
    if (!event.data) {
      return;
    }
    const message = event.data;

    if (message.type === PREPARE_IFRAME) {
      source = event.source;
      const styles = `::-webkit-scrollbar {display:none}`;
      const styleSheet = document.createElement("style");

      styleSheet.innerText = styles;

      document.head.appendChild(styleSheet);
    }

    if (message.type === ENTER_SELECT_MODE) {
      document.addEventListener("click", sendClickedSelector, true);
    }

    if (message.type === QUIT_SELECT_MODE) {
      document.removeEventListener("click", sendClickedSelector, true);

      elementSelectedStylesheet.innerText = "";
    }

    if (message.type === CHANGE_STYLE) {
      if (!source) {
        return;
      }
      let hiddenElementsStylesheet = document.getElementById("HIDDEN_ELEMENTS");
      hiddenElementsStylesheet.innerHTML = "";
      const { selector, property, value } = message;
      beforeChangesSavedStylesheet = document.getElementById("preview-stylesheet");

      beforeChangesStyles = beforeChangesStyles.filter((style) => !(style.selector === selector && style.property === property));

      //If value is a custom font, prepare import via font-face

      if (value.includes(":")) {
        const fontName = `"${value.split(":")[0]}"` || "";
        const fontId = value.split(":")[1] || "";
        const newFontFaceImport = `
        @font-face {
          font-family: "${fontName}";
          src: url("${config.REACT_APP_STYLE_URL}/fonts/${fontId}/binary") format("woff");
        }
        `;
        if (!fontFaceImports.includes(newFontFaceImport)) {
          fontFaceImports.push(
            `
@font-face {
font-family: ${fontName};
src: url("${config.REACT_APP_STYLE_URL}/fonts/${fontId}/binary") format("woff");
}
            `
          );
          fontImportStylesheet.innerText = fontFaceImports.join(" ");
        }
        beforeChangesStyles.push({ selector, property, value: fontName });
      } else {
        beforeChangesStyles.push({ selector, property, value });
      }

      const beforeChangesStylesArray = beforeChangesStyles.map((style) => `${style.selector} {${style.property}:${style.value}} `);

      if (beforeChangesSavedStylesheet) {
        beforeChangesSavedStylesheet.innerText = beforeChangesStylesArray.join(" ");
      }

      source.postMessage({ type: APPERO_WEB_CHANGE_SUCCESSFUL }, "*");
    }

    if (message.type === RESTORE_STYLES) {
      customStylesheet = document.getElementById(CUSTOM_STYLESHEET_ID);

      if (!source) {
        return;
      }

      const { selector } = message;

      beforeChangesSavedStylesheet = document.getElementById("preview-stylesheet");

      beforeChangesStyles = beforeChangesStyles.filter((style) => !(style.selector === selector));

      const beforeChangesStylesArray = beforeChangesStyles.map((style) => `${style.selector} {${style.property}:${style.value}} `);
      beforeChangesSavedStylesheet.innerText = beforeChangesStylesArray.join(" ");

      if (customStylesheet) {
        const customStylesheetParts = customStylesheet.innerText.split(" ").map((part) => (part === selector ? "reset-selector" : part));
        customStylesheet.innerText = customStylesheetParts.join(" ");
      }
    }

    if (message.type === CHANGE_SELECTOR) {
      let styles;
      if (message.selector.includes(".cst-image-product-list") || message.selector.includes(".cst-product-image-fp")) {
        styles = `${message.selector} {opacity:0.5}`;
      } else {
        styles = `${
          message.selector
        }::before {content:"";position:absolute;top:-3px;bottom:-3px;left:-3px;right:-3px;border:3px solid lightblue;border-radius:5px; visibility:visible; opacity:1}`;
      }

      elementSelectedStylesheet.innerText = styles;
    }

    if (message.type === CANCEL_SAVE_CURRENT_STYLE) {
      beforeChangesStyles = [];
      beforeChangesSavedStylesheet = document.getElementById("preview-stylesheet");
      beforeChangesSavedStylesheet.innerText = "";
      document.removeEventListener("click", sendClickedSelector, true);
      elementSelectedStylesheet.innerText = "";
      window.location.reload();
    }

    if (message.type === SAVE_CURRENT_STYLES_REQUEST || message.type === RESET_ALL_STYLES) {
      // Allow time for the new stylesheet to be written and fetched
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }

    if (message.type === SHOW_HIDDEN_ELEMENTS) {
      let hiddenElementsStylesheet = document.getElementById("HIDDEN_ELEMENTS");
      let elementsToShow = [];
      message.elements.forEach((element) => elementsToShow.push(`${element.style} {display:flex; text-decoration:line-through}`));
      hiddenElementsStylesheet.innerHTML = elementsToShow.join(" ");
    }
    if (message.type === HIDE_HIDDEN_ELEMENTS) {
      let hiddenElementsStylesheet = document.getElementById("HIDDEN_ELEMENTS");
      hiddenElementsStylesheet.innerHTML = "";
    }
  };
};
