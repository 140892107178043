import React, { useContext } from 'react'
import { OrderContext } from '../../../stores/OrderProvider'
import './TableInput.css'
import { t } from '../../../i18n'

export default () => {

    const orderStore = useContext(OrderContext)

    return (
        <div className="table-input">
            <div className="table-input-desc">{t('Order.table')}</div> {
                orderStore.order.tableInput ?
                <input type="number" value={orderStore.order.table} onChange={(event) => orderStore.setTable(event.target.value)} />
                :
                <input type="number" value={orderStore.order.table || ''} onChange={(event) => orderStore.setTable(event.target.value)} disabled />
            }
        </div>
    )
}