import i18next from 'i18next'

import en from './translations/en.json'
import fr from './translations/fr.json'
import es from './translations/es.json'
import pt from './translations/pt.json'
import it from './translations/it.json'
import de from './translations/de.json'

i18next.init({
  lng         : localStorage.getItem('language') || navigator.language,
  fallbackLng : 'fr',
  resources   : {
    en : {translation : en},
    fr : {translation : fr},
    es : {translation : es},
    pt : {translation : pt},
    it : {translation : it},
    de : {translation : de},
  }
});

const t = (...args) => i18next.t(...args)

const changeLanguage = (lng, callback) => i18next.changeLanguage(lng.toLowerCase(), callback)

export {i18next, t, changeLanguage}
