import React, { useEffect, useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { t } from '../../i18n'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { OrderContext } from '../../stores/OrderProvider'
import { VenueContext } from '../../stores/VenueProvider'
import formatCurrency from '../../helpers/formatCurrency'
import TopBar from '../../components/TopBar'
import * as API from '../../api/api.js'
import './Payment.css'
import visa from '../../images/visa-logo.png'
import mastercard from '../../images/mastercard-logo.png'
import PhoneInput from 'react-phone-input-2'
import startsWith from 'lodash.startswith'

const CARD_OPTIONS = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#999999',
      color: 'black',
      fontFamily: 'Muli',
      fontWeight: 500,
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: 'blue',
      },
      '::placeholder': {
        color: '#999999',
      },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
}

const TitleBar = ({ title }) => (
  <div className="payment-header">
    <div className="payment-header-title">{title}</div>
    <img src={visa} alt="visa" />
    <img src={mastercard} alt="mastercard" />
  </div>
)

const CardField = ({ onChange }) => (
  <div className="formRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
)

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="formRow">
    <label htmlFor={id} className="formRowLabel">{label}</label>
    <input
      className="formRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange} />
  </div>
)

const SubmitButton = ({ processing, error, children }) => (
  <button className={`submitButton ${error ? 'SubmitButton--error' : ''}`} type="submit" disabled={processing}>
    {processing ? t('Payment.processing') : children}
  </button>
)

const PrivacyMessagePhone = ({ message }) => (
  <div className="privacyMessagePhone">{message}</div>
)
const PrivacyMessage = ({ message }) => (
  <div className="privacyMessage">{message}</div>
)

const NewsLetterToggle = ({ title, onChange }) => (
  <div className="newsletter-toggle">
    <label className="switch">
      <input type="checkbox" onChange={onChange} />
      <span className="slider round"></span>
    </label>
    <div className="slider-title">{title}</div>
  </div>
)

export default () => {
  var localStorage = require('local-storage')

  const stripe = useStripe()
  const elements = useElements()

  const clone = require('rfdc')()

  const orderStore = useContext(OrderContext)
  const venueStore = useContext(VenueContext)

  const costs = orderStore.costs(orderStore.order.products)

  const hasSMSConfirmation = venueStore.venue.payment.sms

  const [error, setError] = useState(null)
  const [cardComplete, setCardComplete] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [paymentIntent, setPaymentIntent] = useState(null)
  const [billingDetails, setBillingDetails] = hasSMSConfirmation ? useState({
    email: localStorage.get('email') || '',
    phone: '',
    name: localStorage.get('name') && localStorage.get('lastname') ? localStorage.get('name') + ' ' + localStorage.get('lastname') : '',
  }) : useState({
    email: localStorage.get('email') || '',
    name: localStorage.get('name') && localStorage.get('lastname') ? localStorage.get('name') + ' ' + localStorage.get('lastname') : '',
  })
  const [intentId, setIntentId] = useState(null)
  const [intentSecret, setIntentSecret] = useState(null)
  const [newsLetter, setNewsLetter] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (hasSMSConfirmation) {
      if (billingDetails.phone.length < 7
        || (billingDetails.phone.startsWith('33') && billingDetails.phone.length !== 11)
        || billingDetails.phone.startsWith('330')) {
        alert(t('Payment.error_phone'))
        return
      }
    }

    if (cardComplete) {
      setProcessing(true)
    }

    const customer = await API.createCustomer(billingDetails.name.trim(), billingDetails.email.trim(), billingDetails.phone, newsLetter)
    if (customer.message) {
      setProcessing(false)
      alert(customer.message)
      return
    }

    const payload = await stripe.confirmCardPayment(intentSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      },
      receipt_email: billingDetails.email
    })

    if (payload.error) {
      setProcessing(false)
      setError(payload.error)
      alert(payload.error.message)
      return
    }

    let submitOrder;
    if (orderStore.order.status === 'paused') {
      // const modifyOrder = await API.modifyOrder(orderStore.order.id, orderStore.order.products)
      // if (modifyOrder.message) {
      //   alert(modifyOrder.message)
      //   return
      // }
      submitOrder = await API.submitOrder(orderStore.order.id)
    } else {
      submitOrder = await API.createAndSubmitOrder(customer.id, intentId, venueStore.venue.id, '', orderStore.order.table, orderStore.order.products, costs, orderStore.order.note)
    }

    if (submitOrder.message) {
      setProcessing(false)
      alert(submitOrder.message)
      return
    }
    orderStore.setOrderId(orderStore.order.status === 'new' ? submitOrder.id : orderStore.order.id)
    orderStore.setStatus('pending')
    orderStore.setPassphrase(submitOrder.passphrase)

    setPaymentIntent(payload.paymentIntent)
    setProcessing(false)
  }

  useEffect(() => {
    if (orderStore.order.total > 0) {
      API.createPaymentIntent(venueStore.venue.id, orderStore.order.total, 'eur', ['card'], venueStore.venue.payment.account_id, venueStore.venue.payment.sms).then(({ id, secret }) => {
        if (id && secret) {
          orderStore.setIntent(id)
          setIntentSecret(secret)
          setIntentId(id)
        }
      })
    }
  }, [])

  return paymentIntent ? (
    hasSMSConfirmation ?
      <Redirect to={{
        pathname: "/summary", state: {
          order: clone(orderStore.order),
          products: clone(orderStore.products),
          costs: clone(costs)
        }
      }} />
      :
      <Redirect to="/confirmation" />
  ) : (
    <>
      <TopBar title={t('Order.title')} />
      <form className="form" onSubmit={handleSubmit}>
        <fieldset className="formGroup">
          <TitleBar title={t('Order.payment')} />
        </fieldset>
        <fieldset className="formGroup">
          <CardField onChange={(e) => {
            setError(e.error)
            setCardComplete(e.complete)
          }} />
        </fieldset>
        <fieldset className="formGroup">
          <Field
            label={t('Payment.fullName')}
            id="name"
            type="text"
            placeholder="Jane Doe"
            required
            autoComplete="name"
            value={billingDetails.name}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, name: e.target.value })
            }} />
          <Field
            label={t('Payment.email')}
            id="email"
            type="email"
            placeholder={t('Payment.why_email')}
            required
            autoComplete="email"
            value={billingDetails.email}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, email: e.target.value })
            }} />
          {
            venueStore.venue.payment.sms ?
              <>
                <div className="formRow">
                  <label htmlFor={'phone'} className="formRowLabel">{t('Payment.phone')}</label>
                  <PhoneInput country={'fr'}
                    value={billingDetails.phone}
                    onChange={phone => setBillingDetails({ ...billingDetails, phone: phone })}
                    isValid={(inputNumber, country, countries) => {
                      return countries.some((country) => {
                        return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                      })
                    }} />
                </div>
                <PrivacyMessagePhone message={t('Payment.why_phone')} />
              </>
              :
              null
          }
        </fieldset>
        <NewsLetterToggle title="Newsletter" onChange={() => { setNewsLetter(!newsLetter) }} />
        <PrivacyMessage message={t('Payment.privacy')} />
        <SubmitButton processing={processing} error={error}>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center'}}>
            <div style={{ marginRight: '5px' }}>{t('Payment.pay')}</div>
            {formatCurrency(orderStore.order.total, venueStore.venue.currency)}
          </div>
        </SubmitButton>
      </form>
    </>
  )
}