import React, { useContext, useEffect, useState } from 'react'
import { t } from '../../i18n'
import { OrderContext } from '../../stores/OrderProvider'
import { VenueContext } from '../../stores/VenueProvider'
import TopBar from '../../components/TopBar'
import Inset from '../../components/Inset'
import StyledText from '../../components/StyledText'
import OrderSummary from './components/OrderSummary'
import Passphrase from '../../pages/Confirmation/components/Passphrase'
import './Summary.css'
import { Link } from 'react-router-dom'

const styles = {
    headline: {
        textAlign: 'center',
        fontWeight: 'bold',
        marginTop: '20px',
        marginBottom: '10px'
    },
    comment: {
        textAlign: 'center',
        marginBottom: '30px',
        marginLeft: '20px',
        marginRight: '20px'
    },
    password: {
        textAlign: 'center',
        fontWeight: 'bold',
        margin: '30px auto 0px auto',
        whiteSpace: 'pre-line'
    },
    reference: {
        textAlign: 'right',
        marginTop: '20px',
        marginRight: '20px'
    }
}

export default props => {
    const orderStore = useContext(OrderContext)
    const venueStore = useContext(VenueContext)

    const [order, setOrder] = useState(props.location.state ? props.location.state.order : orderStore.summary.order)
    const [products, setProducts] = useState(props.location.state ? props.location.state.products : orderStore.summary.products)
    const [costs, setCosts] = useState(props.location.state ? props.location.state.costs : orderStore.summary.costs)

    useEffect(() => {
        orderStore.cleanUp()
        orderStore.setSummary({
            order: order,
            products: products,
            costs: costs
        })
    }, [])

    const Header = () => {
        return (
            <div>
                <TopBar title={t('Order.title')} />
                <Inset value="60px" />
            </div>
        )
    }

    const Footer = () => {
        const origin = order.table ? '/table/' + order.table : '/'
        return (
            <div>
                <Inset value="60px" />
                <Link to={origin}>
                    <div className="summary-footer">{t('Summary.back')}</div>
                </Link>
            </div>
        )
    }

    return (
        <>
            <Header />
            <StyledText style={styles.headline} text={t('Summary.thanks')} />
            {
                order.table ?
                    <StyledText style={styles.comment} text={t('Summary.table')} />
                    :
                    <>
                        <StyledText style={styles.comment} text={t('Summary.sms')} />
                        <StyledText style={styles.password} text={t('Summary.password')} />
                        <Passphrase passphrase={order.passphrase} />
                    </>
            }
            <StyledText style={styles.reference} text={t('Summary.reference', { ref: order.id.substring(0, 5).toUpperCase() })} />
            <OrderSummary order={order} products={products} costs={costs} />
            <Footer />
        </>
    )
}