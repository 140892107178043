import React, { useState, useContext, useEffect } from 'react'
import { VenueContext } from '../../../stores/VenueProvider'
import { ws } from '../../../io/io'
import { t } from '../../../i18n'
import './Queue.css'

export default (props) => {
    const { initialCount } = props
    const [count, setCount] = useState(initialCount)
    const venueStore = useContext(VenueContext)

    const updateQueue = data => {
        if (data.venue === venueStore.venue.id) {
            venueStore.setQueue(data.queue - 1)
            setCount(data.queue)
        }
    }

    useEffect(() => {
        ws.on('queue:update', updateQueue)

        return () => {
            ws.off('queue:update')
        }
    }, [])

    return (
        <div className="queue">
            <div className="queue-label">
                <div className="queue-label-img"></div>
                {t('Menu.queue', { queue: count })}
            </div>
        </div>
    )
}
