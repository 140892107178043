import React, { useState, useEffect } from "react"
import style from "./boxStyle.js"

export default (props) => {

    const [visible, setVisible] = useState(false)
    const [modalStyle, setModalStyle] = useState(style["fadeInUpBox"])

    const setStyles = effect => {
        if (props && props.styles) {
            style[effect].panel = {
                ...style[effect].panel,
                ...props.styles
            }
        }
    }

    const setSize = effect => {
        if (props && props.width) {
            if (props.width.charAt(props.width.length - 1) === "%") {
                // Use Percentage
                const width = props.width.slice(0, -1)
                style[effect].panel.width = width + "%"
            } else if (props.width.charAt(props.width.length - 1) === "x") {
                // Use Pixels
                const width = props.width.slice(0, -2);
                style[effect].panel.width = width + "px"
            } else {
                // Defaults
                style[effect].panel.width = props.width + "px"
            }
        }
        if (props && props.height) {
            if (props.height.charAt(props.height.length - 1) === "%") {
                // Use Percentage
                const height = props.height.slice(0, -1)
                style[effect].panel.height = height + "vh"
            } else if (props.height.charAt(props.height.length - 1) === "x") {
                // Use Pixels
                const height = props.height.slice(0, -2)
                style[effect].panel.height = height + "px"
            } else {
                // Defaults
                style[effect].panel.height = props.height + "px"
            }
        }
    }

    useEffect(() => {
        setVisible(props.isVisible)
        setSize(props.effect)
        setStyles(props.effect)
        setModalStyle(style[props.effect])
    })

    return (
        <div>
            <div style={visible ? modalStyle.container : modalStyle.containerHidden}>
                <div style={visible ? { ...modalStyle.panel } : modalStyle.panelHidden}>
                    {props.children}
                </div>
                <div style={visible ? modalStyle.mask : modalStyle.maskHidden} onClick={props.onClickAway ? props.onClickAway : null} />
            </div>
        </div>
    )
}