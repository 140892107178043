import React, { useContext, useState } from 'react'
import { OrderContext } from '../../../stores/OrderProvider'
import '../Product.css'

export default props => {
    const orderStore = useContext(OrderContext)
    const product = props.product
    const cost = orderStore.order.costs.filter(c => c.id === product.cost_id)[0]
    const onChange = props.onChange
    const [amount, setAmount] = useState(product.costAmount)

    return (
        <div className="product-fp-cost">
            <div className="product-fp-cost-price">
                <div className="product-fp-cost-info">
                    <div className="product-fp-cost-name">{cost.name}</div>
                    <div className="product-fp-cost-desc">{cost.description}</div>
                </div>
                <div className="product-fp-cost-purchase">
                    <div className="product-fp-cost-amount-update" onClick={() => { amount > 1 ? setAmount(amount - 1) : setAmount(0); onChange(amount > 1 ? amount - 1 : 0) }}>-</div>
                    <div className="product-fp-cost-amount-value">{amount}</div>
                    <div className="product-fp-cost-amount-update" onClick={() => { setAmount(amount + 1); onChange(amount + 1) }}>+</div>
                </div>
            </div>
        </div>
    )
}