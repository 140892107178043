import React, { useContext } from 'react'
import formatCurrency from '../../../helpers/formatCurrency'
import { VenueContext } from '../../../stores/VenueProvider'
import { t } from '../../../i18n'
import './OrderSummary.css'


const OrderRow = ({ identifier, product, subProducts, quantity }) => {
    const venueStore = useContext(VenueContext)
    const getBundlePrice = (product, subProducts, index) => {
        if (product.net_price > 0) {
            return formatCurrency(product.net_price, venueStore.venue.currency)
        }
        var total = 0
        let sp = subProducts[index]
        for (let el of sp) {
            total += Object.values(el).map(({ quantity, name, net_price }) => net_price).reduce((sum, net_price) => sum + net_price, 0)
        }
        return formatCurrency(total, venueStore.venue.currency)
    }

    return (
        subProducts && subProducts.length > 0 ?
            subProducts.map((sps, index) =>
                <div className="row-product" key={identifier}>
                    <div className="row-products">
                        <div className="row-product-quantity-name">{'1 x ' + product.name || ''}</div>
                        <div className="row-subproducts">{t('Order.including')}</div>
                        {
                            sps.map(sp =>
                                Object.entries(sp).map(([key, value]) =>
                                    <div className="row-subproduct-quantity-name" key={key}>{value.quantity + ' x ' + value.name || ''}</div>
                                )
                            )
                        }
                    </div>
                    <div className="row-right">
                        <div className="row-product-price">{getBundlePrice(product, subProducts, index)}</div>
                    </div>
                </div>
            )
            :
            <div className="row-product" key={identifier}>
                <div className="row-products">
                    <div className="row-product-quantity-name">{quantity + ' x ' + product.name || ''}</div>
                </div>
                <div className="row-right">
                    <div className="row-product-price">
                        {formatCurrency(product.net_price * quantity, venueStore.venue.currency)}
                    </div>
                </div>
            </div>
    )
}

const CostRow = ({ identifier, product, quantity }) => {
    const venueStore = useContext(VenueContext)
    return (
        <div className="row-product" key={identifier}>
            <div className="row-costs">
                <div className="row-product-quantity-name">{quantity + ' x ' + product.name || ''}</div>
                <div className="row-cost">{product.description || ''}</div>
            </div>
            <div className="row-right">
                <div className="row-product-price">
                    {formatCurrency(product.net_price * product.quantity, venueStore.venue.currency)}
                </div>
            </div>
        </div>
    )
}

export default ({ order, products, costs }) => {
    const venueStore = useContext(VenueContext)
    return (
        <div className="order-content">
            <div className="order-list">
                {
                    Object.entries(order.products || {}).map(([productId, { quantity, subProducts }], i) =>
                        <OrderRow identifier={productId} product={products[productId]} subProducts={subProducts} quantity={quantity} />
                    )
                }
            </div>
            <div className="order-articles">{t('Order.articles', { quantity: Object.values(order.products).reduce((sum, { quantity = 0, bundleId }) => sum + quantity, 0) })}</div>
            {
                costs && costs.length ?
                    <div className="order-costs">
                        <div className="order-costs-title">{t('Order.costs')}</div>
                        <div className="order-costs-list">
                            {
                                costs.map(cost => cost.quantity ? <CostRow identifier={cost.uuid} product={cost} quantity={cost.quantity} /> : null)
                            }
                        </div>
                    </div>
                    :
                    null
            }
            <div className="order-total">
                <div style={{ marginRight: '5px' }}>{t('Order.total')}</div>
                {formatCurrency(order.total, venueStore.venue.currency)}
            </div>
        </div>
    )
}