import { CUSTOM_STYLESHEET_ID } from "./constant";

export const fetchCustomStylesheet = async (venueId) => {
  const config = {
    REACT_APP_STYLE_URL: window._env_.REACT_APP_STYLE_URL,
    STYLESHEET_STORAGE_PATH: window._env_.STYLESHEET_STORAGE_PATH,
  };

  if (!config.REACT_APP_STYLE_URL) {
    console.error(`Missing REACT_APP_STYLE_URL environment variable`);
    return;
  }

  if (!config.STYLESHEET_STORAGE_PATH) {
    console.error(`Missing STYLESHEET_STORAGE_PATH environment variable`);
    return;
  }

  if (!venueId) {
    console.error(`Missing venueId`);
    return;
  }

  try {
    const customStylesheetRequest = await fetch(`${config.REACT_APP_STYLE_URL}/${config.STYLESHEET_STORAGE_PATH}${venueId}.css`, {
      method: "GET",
    });

    if (customStylesheetRequest.status === 404) {
      console.log(customStylesheetRequest.status, "Custom stylesheet not found");

      let beforeChangesSavedStylesheet = document.createElement("style");
      beforeChangesSavedStylesheet.id = "preview-stylesheet";
      beforeChangesSavedStylesheet.innerText = "";

      document.head.appendChild(beforeChangesSavedStylesheet);

      return;
    }

    if (customStylesheetRequest.status < 200 || customStylesheetRequest.status >= 300) {
      console.log(customStylesheetRequest.status, "Unable to fetch custom stylesheet");
      return;
    }
    const customStylesheet = await customStylesheetRequest.text();

    const styleSheet = document.createElement("style");
    styleSheet.innerText = customStylesheet;
    styleSheet.id = CUSTOM_STYLESHEET_ID;

    document.head.appendChild(styleSheet);

    let beforeChangesSavedStylesheet = document.createElement("style");
    beforeChangesSavedStylesheet.id = "preview-stylesheet";
    beforeChangesSavedStylesheet.innerText = "";

    document.head.appendChild(beforeChangesSavedStylesheet);

    let hiddenElementsStylesheet = document.createElement("style");
    hiddenElementsStylesheet.id = "HIDDEN_ELEMENTS";
    document.head.appendChild(hiddenElementsStylesheet);

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
